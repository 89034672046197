import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    Button,
    ListGroup,
    Modal,
    Image,
    Form,
    FormControl,
} from "react-bootstrap";
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import loaderImage from '../../assets/img/capstone_faith_transparent.png'
import { getCroppedImg } from './cropUtils'
import Cropper from 'react-easy-crop';

function UserDetails({ userDetails }) {
    const accessToken = localStorage.getItem('authTokens');
    const [isLoading, setIsLoading] = useState(true);

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [userData, setUserData] = useState({});
    const [userChurch, setUserChurch] = useState({});
    const [editProfile, setEditProfile] = useState(false);
    const [editOtherDetails, setEditOtherDetails] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        serverUrl.get(`/user/user-details/?id=${userId}`, config)
            .then((res) => {
                setUserData(res.data.results[0])
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
        // get the church for that user
        serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
            .then((res) => {
                setUserChurch(res.data.results[0])
                setIsLoading(false)

            })
            .catch((error) => {
                console.log(error)
            })
    }, [userId])

    const handleEditProfile = () => {
        setEditProfile(!editProfile);
    };

    const handleEditOtherDetails = () => {
        setEditOtherDetails(!editOtherDetails);
    };

    const [images, setImages] = useState([]);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropChange = useCallback((crop) => {
        setCrop(crop);
    }, []);

    const handleZoomChange = useCallback((zoom) => {
        setZoom(zoom);
    }, []);

    const handleChangeImage = (e) => {

        const file = e.target.files[0];

        setImages(file);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [cropImage, setCropImage] = useState([]);

    // const handleCropImage = async (e) => {
    //     try {
    //         const croppedImage = await getCroppedImg(images, croppedAreaPixels);
    //         setCropImage(croppedImage);
    //         setImages(null)
    //         // update user image
    //         // Implement your save logic here
    //         const formData = new FormData
    //         formData.append('profile_image', croppedImage);

    //         serverUrl.patch(`/user/edit-user/${userId}`, userData, config)
    //             .then((res) => {
    //                 toast.success('Successfully changed the prfile image ')
    //                 setEditProfile(false)
    //                 setEditOtherDetails(false)
    //                 serverUrl.get(`/user/user-details/?id=${userId}`, config)
    //                     .then((res) => {
    //                         setUserData(res.data.results[0])
    //                     })
    //                     .catch((error) => {
    //                         console.log(error)
    //                     })
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })

    //     } catch (error) {
    //         console.error('Error cropping image:', error);
    //     }
    // };

    const handleCropImage = async (e) => {
        const croppedImage = await getCroppedImg(images, croppedAreaPixels);
        setCropImage(croppedImage);
        setImages(null);

    };


    const handleSaveCropped = async (e) => {
        try {
            if (cropImage instanceof Blob || cropImage instanceof File) {
                // Get the current date and time
                const currentDate = new Date();
                // Construct a unique file name using the current date and time
                const fileName = `cropped_image_${currentDate.getTime()}.png`;
                const formData = new FormData();

                // Append the cropped image to FormData with the constructed file name
                formData.append('profile_picture', cropImage, fileName);

                serverUrl.patch(`/user/${userId}/update-profile/`, formData, config)
                    .then((res) => {
                        toast.success('Successfully changed the profile image');
                        setShowModal(false);
                        setImages(null)
                        setCropImage(null);
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            else {
                toast.error('No image found')
                setShowModal(false);

            }



        } catch (error) {
            console.error('Error cropping image:', error);
        }
    };




    const handleSave = () => {
        // Implement your save logic here
        serverUrl.patch(`/user/edit-user/${userId}`, userData, config)
            .then((res) => {
                toast.success('Successfully changed the data ')
                setEditProfile(false)
                setEditOtherDetails(false)
                serverUrl.get(`/user/user-details/?id=${userId}`, config)
                    .then((res) => {
                        setUserData(res.data.results[0])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };
    // for user with no selected gender
    const [selectedGender, setSelectedGender] = useState(null)

    const handleChange = (e) => {

        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
        // Check if the field being edited is the ID number
        if (name === 'id_number' && editOtherDetails) {
            // get the church for that user
            serverUrl.get(`/user/get-church-admin`)
                .then((res) => {

                })
                .catch((error) => {
                    console.log(error)
                })
            // Display toast error message
            toast.error('You need to contact the admin to change your ID number.');
            return;
        }
    };



    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (
        <div className="mb-4 pt-3 cardProfile">
            <h2>My Profile</h2>

            <div className="topData">
                <div className="d-flex">
                    <Image
                        onClick={() => document.getElementById('imageUpload').click()}
                        src={userData.profile_picture} className="rounded-circle" width="110" />

                    <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleChangeImage}
                    />
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Profile Image</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "40vh" }}>
                            <div>
                                {images && images instanceof File && (
                                    <div>
                                        <Cropper
                                            image={URL.createObjectURL(images)}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={4 / 3}
                                            onCropChange={handleCropChange}
                                            onZoomChange={handleZoomChange}
                                            onCropComplete={onCropComplete}
                                        />
                                    </div>
                                )}
                                {cropImage instanceof Blob && (
                                    <div>
                                        <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-sm btn-secondary text-white" onClick={handleCloseModal}>
                                Close
                            </Button>
                            {cropImage instanceof Blob ? (
                                <Button className="btn btn-sm btn-danger border-0 text-white" onClick={handleSaveCropped}>
                                    Save Image
                                </Button>
                            )
                                :
                                (
                                    <Button className="btn btn-sm btn-danger border-0 text-white" onClick={handleCropImage}>
                                        Crop Image
                                    </Button>
                                )
                            }

                        </Modal.Footer>
                    </Modal>




                    <div className="text-start ms-3">
                        {editProfile ? (
                            <>
                                <Form.Control style={{ fontWeight: "800" }} type="text" name="full_name" value={userData.full_name} onChange={handleChange} />
                                <Form.Control style={{ fontSize: "11px" }} className="text-muted d-block mb-2" type="text" name="phone_number" value={userData.phone_number} onChange={handleChange} />
                                <Form.Control style={{ fontSize: "11px" }} className="text-muted d-block mb-2" type="text" name="email" value={userData.email} onChange={handleChange} />
                            </>
                        ) : (
                            <>
                                <h6 style={{ fontWeight: "800" }}>{userData.full_name}</h6>
                                <span style={{ fontSize: "11px" }} className="text-muted d-block mb-2">{userData.phone_number}</span>
                                <span style={{ fontSize: "11px" }} className="text-muted d-block mb-2">{userData.email}</span>
                            </>
                        )}
                    </div>

                </div>
                {editProfile ? (
                    <>
                        <Button className="btn btn-sm btn-danger border-0" style={{ borderRadius: "10px", height: "5vh", fontSize: "11px", alignSelf: "center" }} onClick={handleSave}>
                            Save
                        </Button>
                    </>
                ) : (
                    <Button className="btn btn-sm border-danger bg-transparent text-dark" style={{ borderRadius: "10px", height: "5vh", fontSize: "11px", width: "5vw", alignSelf: "center" }} onClick={handleEditProfile}>
                        Edit <i className="bi bi-pen"></i>
                    </Button>
                )}

            </div>

            <div className="topDataOther">
                <h6 style={{ fontWeight: "800", textAlign: "left" }}>Other Details</h6>
                <div className="d-flex justify-content-between text-start">
                    <div className="d-block" style={{ width: "45%" }}>
                        <div className="infooOtherDetails">
                            {/* <div className="d-block">
                                <>
                                    <p>Church Assembly</p>
                                    {userChurch ?
                                        <span className="btn btn-sm btn-danger text-white">{userChurch.church_name}</span>
                                        :
                                        <Button className="btn btn-sm btn-dark text-white">Am a guest</Button>
                                    }
                                </>
                            </div> */}
                            {editOtherDetails ? (
                                userData.account_type === 'individual' && userData.gender ?
                                    <div className="d-block">
                                        <p>Gender</p>
                                        <Form.Select name="gender" value={userData.gender} onChange={handleChange} style={{ fontSize: "10px" }}>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Form.Select>
                                    </div>
                                    :
                                    <div className="d-block">
                                        <p>Select gender</p>
                                        <div className='d-flex mt-2 justify-content-between' style={{ width: "10vw" }}>
                                            <Button
                                                style={{ fontSize: "10px" }}
                                                className={`btn btn-sm bg-transparent  ${selectedGender === 'male' ? 'border-danger text-danger' : selectedGender === 'female' ? 'border-secondary text-secondary' : 'border-secondary text-secondary'}`}
                                                value={selectedGender}
                                                onClick={() => setSelectedGender('male')}
                                            >
                                                <b>Male</b>
                                            </Button>
                                            <Button
                                                style={{ fontSize: "10px" }}
                                                className={`btn btn-sm bg-transparent ${selectedGender === 'female' ? 'border-danger text-danger' : selectedGender === 'male' ? 'border-secondary text-secondary' : 'border-secondary text-secondary'}`}
                                                value={selectedGender}
                                                onClick={() => setSelectedGender('female')}
                                            >
                                                <b>Female</b>
                                            </Button>
                                        </div>
                                    </div>
                            ) : (
                                <>
                                    {userData.account_type === 'individual' && (
                                        userData.gender ? (
                                            <div className="d-block">
                                                <p>Gender</p>
                                                <span>{userData.gender}</span>
                                            </div>
                                        )
                                            :
                                            <div className="d-block">
                                                <p>Gender</p>
                                                <div className='d-flex justify-content-between' style={{ width: "10vw" }}>
                                                    <Button
                                                        style={{ fontSize: "10px" }}
                                                        className={`btn btn-sm bg-transparent  border-secondary text-secondary`}                                                >
                                                        <b>Male</b>
                                                    </Button>
                                                    <Button
                                                        style={{ fontSize: "10px" }}
                                                        className={`btn btn-sm bg-transparent  border-secondary text-secondary`}
                                                        value={selectedGender}
                                                        onClick={() => setSelectedGender('female')}
                                                    >
                                                        <b>Female</b>
                                                    </Button>
                                                </div>
                                            </div>
                                    )}
                                </>
                            )}


                        </div>

                        <div className="infooOtherDetails">
                            {userData.id_number &&
                                ((userData.account_type === 'corporate' && !editOtherDetails) || userData.account_type !== 'corporate') ? (
                                <div className="d-block">
                                    <p>{userData.account_type === 'corporate' ? 'Kra Pin' : 'Identification Number'}</p>
                                    <span>
                                        {editOtherDetails ? (
                                            <Form.Control style={{ fontSize: "10px" }} disabled type="text" name="id_number" value={userData.id_number} onChange={handleChange} />
                                        ) : (
                                            userData.id_number
                                        )}
                                    </span>
                                </div>
                            ) : null}
                            <div className="d-block">
                                <p>Country</p>
                                <span>{editOtherDetails ? <Form.Control style={{ fontSize: "10px", marginRight: "-4rem" }} type="text" disabled name="country" value={userData.country} onChange={handleChange} /> : userData.country}</span>
                            </div>


                        </div>
                    </div>

                    {editOtherDetails ? (
                        <>
                            {/* Render editable fields */}
                            <Button className="btn btn-sm btn-danger border-0" style={{ borderRadius: "10px", height: "5vh", fontSize: "11px", alignSelf: "center" }} onClick={handleSave}>
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button className="btn btn-sm border-danger bg-transparent text-dark" style={{ borderRadius: "10px", height: "5vh", fontSize: "11px", alignSelf: "center" }} onClick={handleEditOtherDetails}>
                            Edit <i className="bi bi-pen"></i>
                        </Button>
                    )}
                </div>


            </div>

        </div>
    );
}

export default UserDetails;
