import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Card, CardHeader, Modal, ListGroup, FormControl, Row, Col, Form, FormGroup, Image, FormSelect, Table, Button
} from "react-bootstrap";
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { getCroppedImg } from './cropUtils'
import Cropper from 'react-easy-crop';
import { toast } from "react-toastify";
import loaderImage from '../../assets/img/capstone_faith_transparent.png'

const UserAccountDetails = ({ title }) => {
  const [isLoading, setIsLoading] = useState(true);

  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token to get the user id
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [userData, setUserData] = useState({})
  const [profileData, setprofileData] = useState([])
  const [profilePhoneNumber, setProfilePhoneNumber] = useState([])
  const [userProfileName, setUserProfileName] = useState('')

  useEffect(() => {
    setIsLoading(true)
    serverUrl.get(`/user/user-details/?id=${userId}`, config)
      .then((res) => {
        setUserData(res.data.results[0])
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error)
      })
    // get the profile data 
    serverUrl.get(`/user/profile/?user=${userId}`, config)
      .then((res) => {
        setprofileData(res.data.results)
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error)
      })

    // get the user other phone numbers
    serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
      .then((res) => {
        setProfilePhoneNumber(res.data.results)
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error)
      })
  }, [userId])

  const [showAccounts, setShowAccounts] = useState(false);

  const handleAccountClose = () => setShowAccounts(false);
  const handleAccountShow = () => setShowAccounts(true);

  const [showAddAccounts, setShowAddAccounts] = useState(false);
  const [selectedUserProfileRelation, setSelectedUserProfileRelation] = useState('')
  const handleUserProfileRelationChange = (event) => {
    setSelectedUserProfileRelation(event.target.value);
  };
  const handleAddAccountClose = () => {
    setShowAddAccounts(false)
    setShowAccounts(true)
  }

  const handleAddAccountShow = () => {
    setShowAddAccounts(true)
    setShowAccounts(false)
  }

  const [showEditAccounts, setShowEditAccounts] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null)
  const handleEditAccountClose = () => {
    setShowEditAccounts(false)
  }
  const handleEditAccountShow = (id) => {
    setShowEditAccounts(true)
    setSelectedProfile(id)
  }

  const [profileEdit, setProfileDataEdit] = useState({
    profile_name: '',
    relation: '',
  });

  useEffect(() => {
    if (selectedProfile) {

      setProfileDataEdit({
        profile_name: selectedProfile.profile_name || '',
        relation: selectedProfile.relation || '',
      });
    }

  }, [selectedProfile]);

  const [editedPhoneNumber, setEditedPhoneNumber] = useState({
    phone_number: '',
  });
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const handleEditPhoneNumberShow = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
    setShowEditModal(true);
  };

  const handleDeletePhoneNumberShow = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    if (selectedPhoneNumber) {

      setEditedPhoneNumber({
        phone_number: selectedPhoneNumber.phone_number || '',
      });
    }

  }, [selectedPhoneNumber]);

  const handleChange = (e) => {

    const { name, value } = e.target;
    setProfileDataEdit(prevData => ({
      ...prevData,
      [name]: value
    }));

    setEditedPhoneNumber(prevData => ({
      ...prevData,
      [name]: value
    }));
  };



  const [showDeleteAccounts, setShowDeleteAccounts] = useState(false);
  const handleDeleteAccountClose = () => {
    setShowDeleteAccounts(false)
  }

  const handleDeleteAccountShow = (profile) => {
    setShowDeleteAccounts(true)
    setSelectedProfile(profile)
  }

  const [showAddPhoneNumbers, setShowAddPhoneNumbers] = useState(false);
  const handleAddPhoneNumberClose = () => {
    setShowAddPhoneNumbers(false)
  }

  const handleAddPhoneNumberShow = () => {
    setShowAddPhoneNumbers(true)
  }

  const [showEditPhoneNumbers, setShowEditPhoneNumbers] = useState(false);
  const handleEditPhoneNumberClose = () => {
    setShowEditPhoneNumbers(false)
  }




  const [showDeletePhoneNumbers, setShowDeletePhoneNumbers] = useState(false);
  const handleDeletePhoneNumberClose = () => {
    setShowDeletePhoneNumbers(false)
  }



  const [images, setImages] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const handleZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const handleChangeImage = (e) => {

    const file = e.target.files[0];

    setImages(file);
  };
  const [cropImage, setCropImage] = useState([]);
  const handleCropImage = async (e) => {

    try {
      const croppedImage = await getCroppedImg(images, croppedAreaPixels);
      setCropImage(croppedImage);
      console.log(croppedImage)
      setImages(null)
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };


  // profile crud functionalities
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };
  const filteredData = profileData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;

  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  function handleAddNewProfileAccount(e) {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();

    // Append user, relation, and profile_name fields
    formData.append('user', userId);
    formData.append('relation', selectedUserProfileRelation);
    formData.append('profile_name', userProfileName);

    if (cropImage instanceof Blob || cropImage instanceof File) {
      // Get the current date and time
      const currentDate = new Date();
      // Construct a unique file name using the current date and time
      const fileName = `cropped_image_${currentDate.getTime()}.png`;

      // Append the cropped image to FormData with the constructed file name
      formData.append('image', cropImage, fileName);
    }
    // upload the data
    serverUrl.post(`/user/profile/`, formData, config)
      .then((res) => {
        toast.success('Succesfully added the Account')
        setImages(null);
        setSelectedUserProfileRelation(null);
        setUserProfileName(null);
        setShowAddAccounts(false)
        handleChangeAccountType('other')
        setShowAccounts(true)
        setCropImage(null)
        // get the profiles for the current user
        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setprofileData(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleEditNewProfileAccount(e) {
    e.preventDefault();
    // Create a new FormData object
    const formData = new FormData();

    // Append user, relation, and profile_name fields
    formData.append('user', userId);
    formData.append('relation', profileEdit.profile_name);
    formData.append('profile_name', profileEdit.profile_name);
    if (cropImage instanceof Blob || cropImage instanceof File) {
      // Get the current date and time
      const currentDate = new Date();
      // Construct a unique file name using the current date and time
      const fileName = `cropped_image_${currentDate.getTime()}.png`;

      // Append the cropped image to FormData with the constructed file name
      formData.append('image', cropImage, fileName);
    }
    // upload the data
    serverUrl.patch(`/user/profile/`, formData, config)
      .then((res) => {
        toast.success('Succesfully added the Account')
        setImages(null);
        setSelectedUserProfileRelation(null);
        setUserProfileName(null);
        setShowAddAccounts(false)
        setShowAccounts(true)

        // get the profiles for the current user
        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setprofileData(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDeleteProfileAccount = () => {
    serverUrl.delete(`/user/profile/${selectedProfile.id}/`, config)
      .then((res) => {
        toast.success('Succesfully deleted the profile')
        setShowDeleteAccounts(false)

        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setprofileData(res.data.results)

          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }



  // phone number crud
  const [searchQueryPhone, setSearchQueryPhone] = useState("");
  const [selectedRowPhone, setSelectedRowPhone] = useState(null);

  const handleSearchPhone = (event) => {
    setSearchQueryPhone(event.target.value);
    setSelectedRowPhone(null); // Reset selected row when user starts a new search
  };
  const filteredDataPhone = profilePhoneNumber.filter((item) => {
    const searchableFieldsPhone = Object.values(item).join(" ").toLowerCase();
    return searchableFieldsPhone.includes(searchQueryPhone.toLowerCase());
  });
  const [currentPagePhone, setCurrentPagePhone] = useState(0);
  const itemsPerPagePhone = 5;

  const totalPagesPhone = Math.ceil(filteredDataPhone.length / itemsPerPagePhone);
  const startIndexPhone = currentPagePhone * itemsPerPagePhone;
  const endIndexPhone = currentPagePhone === totalPagesPhone - 1 ? filteredDataPhone.length : startIndex + itemsPerPage;

  const displayedItemsPhone = filteredDataPhone.slice(startIndexPhone, endIndexPhone);

  const handlePrevPagePhone = () => {
    setCurrentPagePhone(currentPagePhone - 1);
  };

  const handleNextPagePhone = () => {
    setCurrentPagePhone(currentPagePhone + 1);
  };
  function handleAddPhone(e) {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();

    // Append user, relation, and profile_name fields
    formData.append('user', userId);
    formData.append('relation', selectedUserProfileRelation);
    formData.append('profile_name', userProfileName);

    if (cropImage instanceof Blob || cropImage instanceof File) {
      // Get the current date and time
      const currentDate = new Date();
      // Construct a unique file name using the current date and time
      const fileName = `cropped_image_${currentDate.getTime()}.png`;

      // Append the cropped image to FormData with the constructed file name
      formData.append('image', cropImage, fileName);
    }
    // upload the data
    serverUrl.post(`/user/profile/`, formData, config)
      .then((res) => {
        toast.success('Succesfully added the Account')
        setImages(null);
        setSelectedUserProfileRelation(null);
        setUserProfileName(null);
        setShowAddAccounts(false)
        handleChangeAccountType('other')
        setShowAccounts(true)
        setCropImage(null)
        // get the profiles for the current user
        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setUserProfile(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleEditPhone(e) {
    e.preventDefault();
    // Create a new FormData object
    const formData = new FormData();

    // Append user, relation, and profile_name fields
    formData.append('user', userId);
    formData.append('relation', profileEdit.profile_name);
    formData.append('profile_name', profileEdit.profile_name);
    if (cropImage instanceof Blob || cropImage instanceof File) {
      // Get the current date and time
      const currentDate = new Date();
      // Construct a unique file name using the current date and time
      const fileName = `cropped_image_${currentDate.getTime()}.png`;

      // Append the cropped image to FormData with the constructed file name
      formData.append('image', cropImage, fileName);
    }
    // upload the data
    serverUrl.patch(`/user/profile/`, formData, config)
      .then((res) => {
        toast.success('Succesfully added the Account')
        setImages(null);
        setSelectedUserProfileRelation(null);
        setUserProfileName(null);
        setShowAddAccounts(false)
        setShowAccounts(true)

        // get the profiles for the current user
        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setprofileData(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDeletePhone = () => {
    serverUrl.delete(`/user/profile/${selectedProfile.id}/`, config)
      .then((res) => {
        toast.success('Succesfully deleted the profile')
        setShowDeleteAccounts(false)

        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setprofileData(res.data.results)

          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModalPhone, setShowAddModalPhone] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [addedPhone, setAddedPhone] = useState('')

  const handleAddPhoneNumber = () => {
    const data = {
      user: userId,
      phone_number: addedPhone,
      country_name: 'Kenya',
      country_code: '+254'
    }
    serverUrl.post(`/user/user-linked-phone-numbers/`, data, config)
      .then((res) => {
        serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
          .then((res) => {
            toast.success('Succesfully added the phone number')
            setProfilePhoneNumber(res.data.results)
            setIsLoading(false)
            setShowAddModalPhone(false);

          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })

  };

  const handleEditPhoneNumber = () => {
    const data = {
      phone_number: editedPhoneNumber.phone_number
    }
    serverUrl.patch(`/user/user-linked-phone-numbers/${selectedPhoneNumber.id}`, data, config)
      .then((res) => {
        serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
          .then((res) => {
            setProfilePhoneNumber(res.data.results)
            toast.success('Succesfully edited the phone number')

            setIsLoading(false)
            setShowEditModal(false);

          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })

  };

  const handleDeletePhoneNumber = () => {
    serverUrl.delete(`/user/user-linked-phone-numbers/${selectedPhoneNumber.id}`, config)
      .then((res) => {
        serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
          .then((res) => {
            setProfilePhoneNumber(res.data.results)
            setIsLoading(false)
            setShowDeleteConfirmation(false);
            toast.success('Succesfully deleted the phone number')


          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  };

  return (
    <div className="mb-4 pt-3 cardProfile">
      <div className="d-flex justify-content-between">
        <h2>My Accounts</h2>
        <Button className='btn btn-sm btn-danger text-white border-0' onClick={handleAddAccountShow}>Add Account</Button>
      </div>
      <Form className="searchBar" style={{ width: "12vw", right: "0px" }}>
        <FormControl type="text" placeholder="Search" onChange={handleSearch} />
      </Form>
      <div className="topData">
        <div className='tasks' >

          <Table className='table'>
            <tbody>
              {displayedItems.length === 0 ?
                <p>No account found</p>
                :
                displayedItems.map((profile) => (
                  <tr>
                    <td style={{ width: "10%" }}>
                      <Image className="rounded-circle" src={profile.image} style={{ width: "75%", height: "auto" }} />
                    </td>
                    <td style={{ alignContent: "center" }}>
                      {profile.profile_name}
                    </td>
                    <td className="text-muted" style={{ alignContent: "center" }}>
                      {profile.relation}
                    </td>
                    <td style={{ alignContent: "center" }}>
                      <Button className='btn btn-sm bg-transparent text-danger border-danger me-3' onClick={() => handleEditAccountShow(profile)}>Edit <i className="bi bi-pen"></i></Button>
                      <Button className='btn btn-sm bg-dark text-white border-0' onClick={() => handleDeleteAccountShow(profile)}>Delete <i className="bi bi-trash"></i></Button>
                    </td>
                  </tr>

                ))
              }


            </tbody>

          </Table>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${profileData.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>
          </div>
        </div>

      </div>


      <div className="d-flex justify-content-between mt-3">
        <h2>My Other Phone Number</h2>
        <Button className='btn btn-sm btn-danger text-white border-0' onClick={() => setShowAddModalPhone(true)}>Add Phone Number</Button>
      </div>
      <Form className="searchBar" style={{ width: "12vw", right: "0px" }}>
        <FormControl type="text" placeholder="Search" onChange={handleSearchPhone} />
      </Form>
      <div className="topData">
        <div className='tasks' >
          <Table className='table'>
            <tbody>
              {displayedItemsPhone.length === 0 ?
                <p>No phone number found</p>
                :
                displayedItemsPhone.map((profile) => (
                  <tr>
                    <td>
                      {profile.phone_number}
                    </td>
                    <td>
                      <Button className='btn btn-sm bg-transparent text-danger border-danger me-3' onClick={() => handleEditPhoneNumberShow(profile)}>Edit <i className="bi bi-pen"></i></Button>
                      <Button className='btn btn-sm bg-dark text-white border-0' onClick={() => handleDeletePhoneNumberShow(profile)}>Delete <i className="bi bi-trash"></i></Button>
                    </td>
                  </tr>

                ))
              }


            </tbody>

          </Table>

          <div className="pagination">
            <button onClick={handlePrevPagePhone} disabled={currentPagePhone === 0}>
              &lt;
            </button>
            <span>{`${startIndexPhone + 1}-${endIndexPhone} of ${profilePhoneNumber.length}`}</span>
            <button onClick={handleNextPagePhone} disabled={currentPagePhone === totalPagesPhone - 1}>
              &gt;
            </button>
          </div>
        </div>

      </div>


      {/* modal to add account */}
      <Modal show={showAddAccounts} onHide={handleAddAccountClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Add Account</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }}>
          <Form className='makePledgeForm' onSubmit={handleAddNewProfileAccount}>
            <input
              type='text'
              placeholder='Enter Name'
              className='input-field'
              onChange={(e) => setUserProfileName(e.target.value)}
              style={{ fontSize: "14px" }}
            />
            <FormSelect onChange={handleUserProfileRelationChange} style={{ fontSize: "14px" }}
            >
              <option selected disabled value=''>Select relation</option>
              <option value='child'>Child</option>
              <option value='other'>Other</option>
            </FormSelect>

            <Button style={{ fontSize: '14px' }} className="btn btn-sm bg-transparent text-danger border-danger" onClick={() => document.getElementById('imageUpload').click()}>Select Photo</Button>
            <br />
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            {images && images instanceof File && (
              <div>
                <Cropper
                  image={URL.createObjectURL(images)}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}
            {cropImage instanceof Blob && (
              <div>
                <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
              </div>
            )}





          </Form>

        </Modal.Body>
        <Modal.Footer>
          {images && images instanceof File ? (

            <p onClick={handleCropImage}>Crop Image</p>
          ) : (
            <>
              <p className='text-dark' style={{cursor: 'pointer'}} onClick={handleAddAccountClose}>Close</p>
              <p className='text-danger' style={{cursor: 'pointer'}} onClick={handleAddNewProfileAccount}>Save</p>
            </>
          )}


        </Modal.Footer>

      </Modal>

      {/* modal to edit account */}
      <Modal show={showEditAccounts} onHide={handleEditAccountClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Edit Account</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }}>
          <Form className='makePledgeForm' onSubmit={handleEditNewProfileAccount}>
            <input
              type='text'
              placeholder='Enter Name'
              className='input-field'
              value={profileEdit.profile_name}
              onChange={handleChange}
              style={{ fontSize: "14px" }}
            />
            <FormSelect onChange={handleChange} value={profileEdit.relation} style={{ fontSize: "14px" }}
            >
              <option selected disabled value=''>Select relation</option>
              <option value='child'>Child</option>
              <option value='other'>Other</option>
            </FormSelect>

            <Button style={{ fontSize: '14px' }} className="btn btn-sm bg-transparent text-danger border-danger"
              onClick={() => document.getElementById('imageUpload').click()}>Select Photo</Button>
            <br />
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            {images && images instanceof File && (
              <div>
                <Cropper
                  image={URL.createObjectURL(images)}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}
            {cropImage instanceof Blob && (
              <div>
                <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
              </div>
            )}





          </Form>

        </Modal.Body>
        <Modal.Footer>
          {images && images instanceof File ? (

            <p onClick={handleCropImage}>Crop Image</p>
          ) : (
            <>
              <p className='text-dark' onClick={handleAddAccountClose}>Close</p>
              <p className='text-danger' onClick={handleAddNewProfileAccount}>Save</p>
            </>
          )}


        </Modal.Footer>

      </Modal>

      {/* modal to delete account */}
      <Modal show={showDeleteAccounts} onHide={handleDeleteAccountClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }}>
          {selectedProfile &&

            <p>Are you sure you want to delete account {selectedProfile.profile_name}?</p>
          }

        </Modal.Body>
        <Modal.Footer>
          <p className='text-dark' onClick={handleDeleteAccountClose}>Close</p>
          <p className='text-danger' onClick={handleDeleteProfileAccount}>Delete</p>

        </Modal.Footer>

      </Modal>

      {/* add phone */}
      <Modal show={showAddModalPhone} onHide={() => setShowAddModalPhone(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display selected phone number for editing */}
          <input
            value={addedPhone}
            type='text'
            onChange={(e) => setAddedPhone(e.target.value)}
            className="input-field"
          />
          {/* Implement input fields for editing */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setShowAddModalPhone(false)}>Close</Button>
          <Button variant="danger" onClick={handleAddPhoneNumber}>Save</Button>
        </Modal.Footer>
      </Modal>


      {/* edit phone */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display selected phone number for editing */}
          <input
            value={editedPhoneNumber.phone_number}
            type='text'
            onChange={handleChange}
            name='phone_number'
            className="input-field"
          />
          {/* Implement input fields for editing */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setShowEditModal(false)}>Close</Button>
          <Button variant="danger" onClick={handleEditPhoneNumber}>Save</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the phone number?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDeletePhoneNumber}>Delete</Button>
        </Modal.Footer>
      </Modal>

    </div >
  );
};


export default UserAccountDetails;
