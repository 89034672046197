import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col, Image, Modal, Form } from 'react-bootstrap'
import '../../assets/scss/SharedPledgeCard.scss';
import PieChart from './PieChart';
import Citam from '../../assets/img/capstone_faith_transparent.png'
import Coin from '../../assets/img/coin-stack.png'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serverUrl } from '../../serverUrl';
import PhoneNumberValidation from './PhoneNumberValidation';
import Mpesa from '../../assets/img/mpesa.jpeg'
import Cheque from '../../assets/img/cheque.png'
import Credit from '../../assets/img/credit-card.png'
import Cash from '../../assets/img/money.png'
import PayPal from '../../assets/img/paypal.png'
import Bank from '../../assets/img/bank.png'
import Select from 'react-select'

const SharedPledgeCard = ({ title, category, raisedAmount, goalAmount, description, campaignGoal, campaignRaised, currency, image }) => {
  const percentagePledge = (raisedAmount / goalAmount) * 100;
  const percentageCampaign = (campaignRaised / campaignGoal) * 100;
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSendUser = () => {
    toast.error('Kindly login so that you can make the pledge')
    navigate('/login')
  }

  const [showModal, setShowModal] = useState(false)
  const [currentPlatform, platformSet] = useState(false)

  const handleModalOpen = (platform) => {
    setShowModal(true)
    platformSet(platform)

  }
  const handleModalClose = () => {
    setShowModal(false)

  }
  const [pledgeData, PledgeData] = useState({})

  // get the pledge data
  useEffect(() => {
    serverUrl.get(`/pledge/shared-pledge/?payment_code=${id}`)
      .then((res) => {
        PledgeData(res.data.results[0])
      })
      .catch((error) => {
        console.log(error)
      })

  }, [id])



  const ends_on = pledgeData.campaing_ends_on
  const formatDate = (ends_on) => {
    const date = new Date(ends_on);
    const day = date.toLocaleDateString('en-GB', { day: 'numeric' });
    const month = date.toLocaleDateString('en-GB', { month: 'long' });
    const year = date.toLocaleDateString('en-GB', { year: 'numeric' });

    return { day, month, year };
  };
  const formattedDate = formatDate(ends_on);

  // countdown data
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const targetDate = new Date(ends_on).getTime();
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [ends_on]);

  const [contributor, setContributor] = useState({
    fullName: "",
    email: "",
  })
  const handlePledgeInput = (e) => {
    const { name, value } = e.target;

    setChequeData({
      ...chequeData,
      [name]: value,
    });

    setContributor({
      ...contributor,
      [name]: value,
    });
  };

  const banks = [
    {

      "name": "KCB"
    },
    {

      "name": "Standard Chartered"
    },
    {

      "name": "ABSA"
    },
    {

      "name": "NCBA"
    },
    {

      "name": "Prime Bank"
    },
    {

      "name": "Coop Bank"
    },
    {

      "name": "NBK"
    },
    {

      "name": "M-Oriental"
    },
    {

      "name": "Citi Bank"
    },
    {

      "name": "Middle East Bank"
    },
    {

      "name": "Bank of Africa"
    },
    {

      "name": "Consolidated Bank"
    },
    {

      "name": "Credit Bank"
    },
    {

      "name": "Stanbic Bank"
    },
    {

      "name": "ABC Bank"
    },
    {

      "name": "Ecobank"
    },
    {

      "name": "Paramount"
    },
    {

      "name": "Kingdom Bank"
    },
    {

      "name": "GT Bank"
    },
    {

      "name": "Victoria Bank"
    },
    {

      "name": "Guardian Bank"
    },
    {

      "name": "I&M Bank"
    },
    {

      "name": "Housing Finance"
    },
    {

      "name": "DTB"
    },
    {

      "name": "Mayfair Bank"
    },
    {

      "name": "Sidian Bank"
    },
    {

      "name": "Equity Bank"
    },
    {

      "name": "Family Bank"
    },
    {

      "name": "Gulf African Bank"
    },
    {
      "name": "Premier Bank Kenya"
    },
    {

      "name": "Dubai Islamic Bank"
    },
    {

      "name": "UBA"
    },
    {

      "name": "KWFT"
    },
    {

      "name": "Stima Sacco"
    },
    {

      "name": "Telkom Kenya"
    },
    {

      "name": "Choice Microfinance Bank"
    }
  ]

  const [selectedBank, setSelectedBank] = useState(null);

  const optionList = banks.map(bank => ({
    value: bank.name,
    label: bank.name
  }));
  const handleSelectChange = selectedOption => {
    setSelectedBank(selectedOption);
  };

  const [modalShow, setModalShow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handleOpenRedeemModal = () => {
    setModalShow(true);

  }
  const handleShowModal = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setSelectedPaymentMethod('');
  };
  const [donationAmount, setDonationAmount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [bankTransferOption, setBankTransferOption] = useState('PESALINK');

  const handleButtonClick = (index, amount) => {
    setActiveIndex(index);
    setDonationAmount(amount)
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleBankTransferOptionChange = (option) => {
    setBankTransferOption(option);
  };

  const handleBankTransferSelect = (option) => {
    handleBankTransferOptionChange(option);
    setSelectedPaymentMethod(option);
  };
  const [pledgeFormData, makePledgeFormData] = useState({
    pledgeAmount: "",
    currency: "KSH",
    payment_frequency: "",
    installment: "",
    estimated_amount: ""
  })

  const [chequeData, setChequeData] = useState({
    chequeNumber: "",
    currency: "KSH",
    transaction_date: "",
    amount: "",
  })
  // get post the data for the redeemption
  const [addedContributor, setAddedContributor] = useState({})

  const phone_number = localStorage.getItem('number')
  const contributorName = localStorage.getItem('contributor')
  const contributorPhone = localStorage.getItem('contributorNumber')

  function handleRedeemAdd(e) {
    const description = `${addedContributor.id}-1-${id}`

    e.preventDefault()

    if (selectedPaymentMethod === 'MPESA') {
      const data = {
        amount: donationAmount,
        phone_number: '+' + phone_number,
        reference: id,
        description: description,
        source: "web",
        // updated_by: userId,
      }

      // if (userChurch) {
      //   data.church = userChurch.id;
      // }
      serverUrl.post(`giving/checkout/`, data)
        .then((res) => {
          setSelectedPaymentMethod(null);
          toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
          // Reload the page after 8 seconds
          setTimeout(() => {
            window.location.reload();
          }, 8000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (selectedPaymentMethod === 'CHEQUE') {
      const formattedDate = chequeData.transaction_date.replace(/-/g, '');
      // const firstName = userPledgeData.shared_user;


      const formData = new FormData();
      formData.append("payment_code", id);
      formData.append("cheque_number", chequeData.chequeNumber);
      formData.append("currency", chequeData.currency);
      formData.append("transcation_amount", parseInt(donationAmount));
      formData.append("transaction_date", chequeData.transaction_date);
      formData.append("transcation_time", formattedDate + '000000');
      formData.append("payment_channel", "cheque");
      formData.append("phone_number", phone_number);
      formData.append("status", "pending");
      // formData.append("updated_by", userId);
      formData.append("source", "web");
      formData.append("first_name", addedContributor.full_name);
      formData.append("bill_ref_number", id);

      // Check if cheque image is present
      if (images) {
        formData.append("cheque_image", images);
      }
      if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
        toast.error('Please fill in all the required fields')
        return
      }
      // if (userChurch) {
      //   formData.append("church", userChurch.id);
      // }

      serverUrl.post(`/giving/create-ctb-transaction/`, formData)
        .then((res) => {
          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    else if (selectedPaymentMethod === 'CARD') {
      let transactionDate = null; // Initialize transaction date to null

      if (chequeData && chequeData.transaction_date) {
        // If transaction date exists in chequeData
        transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
      } else {
        // If transaction date is null, use the current date
        transactionDate = new Date(); // Current date
      }

      // Format the date to YYYYMMDD format
      const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');



      const formData = new FormData();
      formData.append("payment_code", id);
      formData.append("transcation_id", chequeData.chequeNumber);
      formData.append("currency", chequeData.currency);
      formData.append("transcation_amount", parseInt(donationAmount));
      formData.append("transaction_date", chequeData.transaction_date);
      formData.append("transcation_time", formattedDate + '000000');
      formData.append("payment_channel", "card");
      formData.append("phone_number", phone_number);
      formData.append("status", "pending");
      // formData.append("updated_by", userId);
      formData.append("source", "web");
      formData.append("first_name", addedContributor.full_name);
      formData.append("bill_ref_number", id);
      if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
        toast.error('Please fill in all the required fields')
        return
      }

      // Check if cheque image is present
      if (images) {
        formData.append("cheque_image", images);
      }
      // if (userChurch) {
      //   formData.append("church", userChurch.id);
      // }

      serverUrl.post(`/giving/create-ctb-transaction/`, formData)
        .then((res) => {
          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    else if (selectedPaymentMethod === 'BANK TRANSFER') {


      const bankData = {
        payment_code: id,
        bank_payment_channel: bankTransferOption,
        bank_transaction_reference: chequeData.chequeNumber,
        currency: chequeData.currency,
        transcation_amount: donationAmount,
        transaction_date: chequeData.transaction_date,
        transcation_time: formattedDate + '000000',
        payment_channel: "bank",
        phone_number: phone_number,
        status: "pending",
        // updated_by: userId,
        source: "web",
        first_name: addedContributor.full_name,
        bill_ref_number: id,

      };


      if (selectedBank) {
        bankData.bank = selectedBank.value
      }
      else {
        toast.error('Bank should not be null')
        return
      }
      if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date | !bankTransferOption || !selectedBank.value) {
        toast.error('Please fill in all the required fields')
        return
      }

      serverUrl.post(`/giving/create-ctb-transaction/`, bankData)
        .then((res) => {
          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }
  const [images, setImages] = useState([]);

  const handleChangeImage = (e) => {

    const file = e.target.files[0];

    setImages(file);
  };
  const [monthsToFutureDate, setMonthsToFutureDate] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    const futureDate = new Date(ends_on);
    const differenceInMonths = (futureDate.getFullYear() - currentDate.getFullYear()) * 12 + (futureDate.getMonth() - currentDate.getMonth());
    setMonthsToFutureDate(differenceInMonths);
  });

  const [isLoading, setIsLoading] = useState(false)

  function handleContributor(e) {
    e.preventDefault()
    if (!contributor.fullName) {
      toast.error('Provide the full name')
      return
    }
    const phone_number = localStorage.getItem('number')
    setIsLoading(true)
    const data = {
      phone_number: '+' + phone_number,
      full_name: contributor.fullName,
      // email:contributor.email,
      user: pledgeData.plede_user_id,
      payment_code: id,
      user: pledgeData.plede_user_id,

    }


    if (phone_number) {
      serverUrl.get(`/user/pledge-contributors/?payment_code=${id}`)
        .then((res) => {
          const userFound = res.data.results.filter((user) => user.phone_number === data.phone_number)

          if (userFound.length === 0) {
            serverUrl.post(`/user/pledge-contributors/`, data)
              .then((res) => {
                setAddedContributor(res.data.results[0])
                if (currentPlatform === 'mobile') {
                  navigate(`/donation-shared/${id}`)
                  if (addedContributor) {
                    localStorage.setItem('contributor', res.data.results[0].id)
                    localStorage.setItem('contributorNumber', res.data.results[0].phone_number)
                  }
                }
                else {
                  setModalShow(true);
                  setShowModal(false)

                }
              })
              .catch((error) => {
                console.log(error)
              })
          }

          else {
            setAddedContributor(userFound[0])

            if (currentPlatform === 'mobile') {
              navigate(`/donation-shared/${id}`)
              if (addedContributor) {
                localStorage.setItem('contributor', userFound[0].id)
                localStorage.setItem('contributorNumber', userFound[0].phone_number)
                localStorage.setItem('contributorName', userFound[0].full_name)
              }
            }
            else {
              setModalShow(true);
              setShowModal(false)
              if (addedContributor) {
                localStorage.setItem('contributor', userFound[0].id)
                localStorage.setItem('contributorNumber', userFound[0].phone_number)
                localStorage.setItem('contributorName', userFound[0].full_name)


              }
            }
          }



        })
        .catch((error) => {
          console.log(error)
        })

    }

  }
  const formatAmount = (amount) => {
    if (amount >= 1000000000) {
      return (amount / 1000000000).toFixed(2) + 'B';
    } else if (amount >= 1000000) {
      return (amount / 1000000).toFixed(2) + 'M';
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(2) + 'K';
    } else {
      return amount;
    }
  };
  let fillColorClass;

  if (percentagePledge < 33) {
    fillColorClass = '#e74c3c';
  } else if (percentagePledge < 67) {
    fillColorClass = '#f39c12';
  } else {
    fillColorClass = '#2ecc71';

  }

  let combinedName;
  if (pledgeData && pledgeData.shared_user) {
    const splitName = pledgeData.shared_user.split(' ');
    const firstName = splitName[0];
    let secondNameInitial = null;

    if (splitName.length > 1 && splitName[1].length > 0) {
      secondNameInitial = splitName[1].charAt(0);
    }

    combinedName = `${firstName} ${secondNameInitial !== null ? secondNameInitial + '.' : ''}`;
  } else {
    combinedName = '';
  }

  return (
    <div className='sharedPledgeCard'>
      {/* on the web view */}
      <div className="pledgeDisplay">
        {/* <p className='categoryCampaign'>{category}</p> */}
        <h1>{pledgeData.campaing_name}</h1>
        <p className='topRaised'>We Love Our Supporters.{' '}
          <Button className='btn btn-sm btn-danger border-0 text-white p-2 ms-2'>Join us today</Button>
        </p>
        <div className="image-container">
          <img src={image} alt="Placeholder" className='TopDisplayImage' />
        </div>

        {/* the below container */}
        <Container>
          <Card className='TopDisplayCard'>
            <Card.Body>

              <p className='w-10 text-white fw-bold' style={{ width: "11%", alignSelf: "center" }}>
                <span style={{ fontSize: "8px" }}>ENDS ON</span><br></br>
                <span style={{ fontSize: "2rem" }}>{formattedDate.day}</span> {formattedDate.month} {formattedDate.year}
              </p>

              <div className='d-flex  justify-content-between bg-white w-100' style={{ marginBottom: "-1rem", marginRight: "-1rem" }}>
                <div className='p-4' style={{ alignSelf: "center", textAlign: "left" }}>
                  <span className='text-uppercase'>Generosity in action</span><br></br>
                  <span className='fw-bold text-uppercase'>Christian Responsibility and Stewardship</span>
                  <br></br>
                  {monthsToFutureDate &&
                    <p className='text-uppercase text-white border-0 btn btn-sm btn-danger p-2' style={{ fontSize: "13px", fontWeight: "bold", textAlign: "left", borderRadius: "15px" }}>{monthsToFutureDate} months to go</p>


                  }
                </div>

                <div className='d-flex w-50' style={{ alignItems: "center" }}>
                  <div className='d-flex' style={{ alignItems: "center" }}>


                    <div className='d-flex justify-content-between ms-5'>
                      <Button className='btn btn-sm btn-dark fw-bold text-white p-2 me-4' style={{ alignSelf: "center" }} onClick={() => handleModalOpen('web')}>Contribute for {combinedName} </Button>
                    </div>

                  </div>


                </div>


              </div>
            </Card.Body>
          </Card>
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title className='text-danger fw-bold m-auto'>User details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='fw-bold'>Kindly fill in your details so that you can contribute to the pledge</p>
              <Form onSubmit={handleContributor}>
                <div className='d-block'>
                  <div className='d-block'>
                    <label style={{ fontSize: "14px" }}>Full Name <span className='text-danger'>*</span></label>
                    <input className='chequeNumber'
                      type='text'
                      placeholder='Enter your full name'
                      style={{ width: "100%", marginTop: "0.5rem" }}
                      onChange={handlePledgeInput}
                      name='fullName'
                      value={contributor.fullName}

                    />
                  </div>
                  <label style={{ fontSize: "14px" }}>Phone Number</label>
                  <PhoneNumberValidation style={{ width: "36vw" }} className='phoneContribute' />


                </div>
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button className='btn btn-danger border-0 text-white btn-sm' onClick={handleContributor}>Continue</Button>
              <Button className='btn btn-dark text-white btn-sm' onClick={handleModalClose}>Close</Button>
            </Modal.Footer>

          </Modal>


          {/* modal to make redemption of pledge */}
          <Modal size='lg' show={modalShow} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title className='text-danger fw-bold'>Make Redemption</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ fontSize: "14px", fontWeight: "600", marginBottom: "0.5rem", textAlign: "left" }}>Select Mode Of Payment</p>

              <div className='d-flex flex-wrap' style={{ alignItems: "center" }}>

                <div className='mt-2 d-flex '>
                  <div className={'ModeofPayment' + (selectedPaymentMethod === 'MPESA' ? ' selectedPayment' : '')} onClick={() => handleShowModal('MPESA')}>
                    <Image src={Mpesa} />
                    <p>MPESA</p>
                  </div>

                  <div className={'ModeofPayment' + (selectedPaymentMethod === 'CHEQUE' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CHEQUE')}>
                    <Image src={Cheque} />
                    <p>CHEQUE</p>
                  </div>

                  <div className={'ModeofPayment' + (selectedPaymentMethod === 'CARD' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CARD')}>
                    <Image src={Credit} />
                    <p>CARD</p>
                  </div>

                  <div className={'ModeofPayment' + (selectedPaymentMethod === 'PAYPAL' ? ' selectedPayment' : '')} onClick={() => handleShowModal('PAYPAL')}>
                    <Image src={PayPal} />
                    <p>PAYPAL</p>
                  </div>
                  <div className={'ModeofPayment' + (selectedPaymentMethod === 'BANK TRANSFER' ? ' selectedPayment' : '')} onClick={() => handleShowModal('BANK TRANSFER')}>
                    <Image src={Bank} />
                    <p>BANK TRANSFER</p>
                  </div>


                </div>


              </div>

              {selectedPaymentMethod === 'MPESA' && (
                // Content for MPESA
                <>

                  <div className='donation p-2'>
                    <div className='d-flex' style={{ placeContent: "center", width: "24vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                      <p className='kshP'>Ksh</p>
                      <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "10rem", backgroundColor: "transparent", border: "0", outline: "0" }} />

                    </div>

                    <div className='d-flex flex-wrap'>
                      {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                        <Button
                          key={index}
                          className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                          onClick={() => handleButtonClick(index, amount)}
                          onChange={handleAmountChange}

                        >
                          Ksh. {amount.toLocaleString()}
                        </Button>
                      ))}
                    </div>


                  </div>
                </>
              )}
              {selectedPaymentMethod === 'CHEQUE' && (
                // Content for CHEQUE
                <>
                  <div className='donation p-0'>
                    <p className='fw-bold' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                    <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                      <select value={chequeData.currency}
                        onChange={handlePledgeInput}
                        className='currency'
                        style={{ border: "0", color: "#00abbdFF" }}
                        name='currency'>
                        <option className='kshP' value='KSH'>KSH</option>
                        <option className='kshP' value='USD'>USD</option>
                        <option className='kshP' value='EUR'>EUR</option>
                        <option className='kshP' value='GBP'>GBP</option>
                      </select>
                      <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                    </div>

                    <div className='d-flex flex-wrap'>
                      {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                        <Button
                          key={index}
                          className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                          onClick={() => handleButtonClick(index, amount)}
                          onChange={handleAmountChange}

                        >
                          Ksh. {amount.toLocaleString()}
                        </Button>
                      ))}
                    </div>


                  </div>


                  <p className='fw-bold' style={{ fontSize: "12px" }}>Cheque Number <b className='text-danger'>*</b></p>
                  <input
                    className='chequeNumber'
                    style={{ width: "35vw" }}
                    type='text'
                    placeholder='Cheque Number'
                    onChange={handlePledgeInput}
                    name='chequeNumber'
                    value={chequeData.chequeNumber}

                  />

                  <p className='fw-bold' style={{ fontSize: "12px" }}>Image Of Cheque(optional)</p>
                  <Button style={{ fontSize: "14px" }} className='btn btn-sm bg-transparent text-danger border-danger' onClick={() => document.getElementById('imageUpload').click()}>Add cheque photo</Button>

                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleChangeImage}
                  />
                  {images && images instanceof File && (
                    <>
                      <img
                        src={URL.createObjectURL(images)}
                        alt=""
                        className=""
                        style={{ width: '20vw', height: '20vh', margin: "0 auto", display: "block" }}
                      />

                    </>
                  )}


                  <div>
                    <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                    <input
                      className='chequeNumber'
                      onChange={handlePledgeInput}
                      value={chequeData.transaction_date}
                      type='date'
                      name='transaction_date'
                      placeholder='Payment Date'
                      max={new Date().toISOString().slice(0, 10)}
                    />

                  </div>

                </>
              )}
              {selectedPaymentMethod === 'CARD' && (
                // Content for CARD
                <>
                  <div className='donation p-0'>
                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                    <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                      <select value={chequeData.currency}
                        onChange={handlePledgeInput}
                        className='currency'
                        style={{ border: "0", color: "#00abbdFF" }}
                        name='currency'>
                        <option className='kshP' value='KSH'>KSH</option>
                        <option className='kshP' value='USD'>USD</option>
                        <option className='kshP' value='EUR'>EUR</option>
                        <option className='kshP' value='GBP'>GBP</option>
                      </select>
                      <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                    </div>

                    <div className='d-flex flex-wrap'>
                      {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                        <Button
                          key={index}
                          className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                          onClick={() => handleButtonClick(index, amount)}
                          onChange={handleAmountChange}

                        >
                          {chequeData.currency}. {amount.toLocaleString()}
                        </Button>
                      ))}
                    </div>


                  </div>


                  <p className='fw-bold' style={{ fontSize: "12px" }}>Reference Number <b className='text-danger'>*</b></p>
                  <input
                    className='chequeNumber'
                    style={{ width: "35vw" }}
                    type='text'
                    placeholder='Reference Number'
                    onChange={handlePledgeInput}
                    value={chequeData.chequeNumber}
                    name='chequeNumber'
                  />




                  <div>
                    <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                    <input
                      className='chequeNumber'
                      onChange={handlePledgeInput}
                      value={chequeData.transaction_date}
                      type='date'
                      name='transaction_date'
                      placeholder='Payment Date'
                      max={new Date().toISOString().slice(0, 10)}
                    />

                  </div>

                </>
              )}
              {selectedPaymentMethod === 'PAYPAL' && (
                // Content for PAYPAL
                <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>
              )}
              {selectedPaymentMethod === 'BANK TRANSFER' && (
                // Content for BANK TRANSFER
                <>
                  <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Select type of bank transer</p>

                  <div className='mt-3'>
                    <select
                      className='form-select w-50'
                      value={bankTransferOption}
                      onChange={(e) => handleBankTransferOptionChange(e.target.value)}
                      style={{ fontSize: "12px" }}
                    >
                      <option value='PESALINK'>Pesalink</option>
                      <option value='EFT'>EFT</option>
                      <option value='RTGS'>RTGS</option>
                    </select>
                  </div>

                  <>
                    <div className='donation p-0'>
                      <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount</p>

                      <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #00abbdFF" }}>
                        <select value={chequeData.currency}
                          onChange={handlePledgeInput}
                          className='currency'
                          style={{ border: "0", color: "#00abbdFF" }}
                          name='currency'>
                          <option className='kshP' value='KSH'>KSH</option>
                          <option className='kshP' value='USD'>USD</option>
                          <option className='kshP' value='EUR'>EUR</option>
                          <option className='kshP' value='GBP'>GBP</option>
                        </select>
                        <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                      </div>

                      <div className='d-flex flex-wrap'>
                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                          <Button
                            key={index}
                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                            onClick={() => handleButtonClick(index, amount)}
                            onChange={handleAmountChange}

                          >
                            Ksh. {amount.toLocaleString()}
                          </Button>
                        ))}
                      </div>


                    </div>

                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Bank <span className='text-danger'>*</span></p>
                    <div style={{ width: "35vw" }}>
                      <Select
                        options={optionList}
                        placeholder="Select a bank"
                        value={selectedBank}
                        onChange={handleSelectChange}
                        style={{ padding: "0", fontSize: "10px" }}
                        required
                      />

                    </div>


                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Transaction Number(Optional)</p>
                    <input
                      className='chequeNumber'
                      style={{ width: "35vw" }}
                      type='text'
                      placeholder='Transaction Number'
                      onChange={handlePledgeInput}
                      value={chequeData.chequeNumber}
                      name='chequeNumber'
                    />

                    <div>
                      <p className='fw-bold' style={{ fontSize: "12px" }}>Date</p>
                      <input
                        className='chequeNumber'
                        onChange={handlePledgeInput}
                        value={chequeData.transaction_date}
                        type='date'
                        name='transaction_date'
                        placeholder='Payment Date'
                        max={new Date().toISOString().slice(0, 10)}
                      />

                    </div>

                  </>
                </>

              )}

            </Modal.Body>
            <Modal.Footer>
              <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 w-25' onClick={handleRedeemAdd}>Redeem for {combinedName}</Button>

            </Modal.Footer>
          </Modal>

          <Card className='BottomDisplayCard'>
            <Card.Body>
              <Row>
                <Col md={5} style={{ textAlign: "left" }}>
                  <span className='text-danger fw-bold'><i className='bi bi-person-fill me-2'></i> Shared by {pledgeData.shared_user}</span>
                  <p>{pledgeData.campaing_description}</p>


                </Col>

                <Col md={7}>
                  <div className='d-flex justify-content-between'>
                    <div className='pledgeProgressPie'>
                      <p className='fw-bold'>Pledge Progress</p>
                      <PieChart
                        percentage={percentagePledge}
                      />
                    </div>

                    <div style={{ alignSelf: "center" }}>

                      <span className='fw-bold' style={{ fontSize: "1rem" }}>Pledge Target</span><br></br>
                      {pledgeData.pledge_target && (
                        <p className='fw-bold' style={{ width: "10vw" }}><span className='text-danger'>{pledgeData.pledge_currency}</span><span style={{ fontSize: "1.3rem" }}> {pledgeData.pledge_target.toLocaleString('en-US')}</span></p>

                      )}
                    </div>

                    <div className='campaignProgressPie'>

                      <p className='fw-bold'>Campaign Progress</p>
                      <PieChart
                        percentage={percentageCampaign}
                      />
                    </div>


                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>



      </div>

      {/* on the mobile view */}
      <div className='pledeDisplayMobile'>
        <img src={image} alt="Placeholder" className='TopDisplayImage' />

        <div className="campaign-info">
          <div className="campaign-header">
            <h5>{pledgeData.campaing_name}</h5>
            <div className="d-flex align-items-center">
              <Image src={pledgeData.user_image} className='profileImage' />
              <div className="donate-button">{pledgeData.shared_user}</div>
            </div>
          </div>

          <Card className="campaign-details">
            <Card.Body>
              <div className='d-flex justify-content-between' style={{ marginTop: "-10px" }}>
                <div className="my-pledge">My Pledge</div>
                <div className="my-pledge" >Contributed</div>

              </div>
              <div className='d-flex justify-content-between'>
                {pledgeData.pledge_target && (
                  <span className="amount fw-bold"><b className=''>KSH.</b>{parseInt(pledgeData.pledge_target).toLocaleString()}</span>

                )
                }
                <span className="amount fw-bold" style={{color:fillColorClass}}><b>KSH.</b> {parseInt(raisedAmount).toLocaleString()}</span>

              </div>

              <div className="progress">
                <div className="progress-fill" style={{ width: `${percentagePledge}%`,backgroundColor:fillColorClass }} />
              </div>

              <div className='d-flex justify-content-between'>
                {monthsToFutureDate &&
                  <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>{monthsToFutureDate} months to go</div>
                }

                <div className="days-left">
                  <Image src={Coin} className='me-1' style={{ width: "12%" }} />
                  {pledgeData.total_contributions} contributions</div>
              </div>

            </Card.Body>
          </Card>




          <div className="about">
            <h5>About Campaign</h5>
            <p>{description}</p>
          </div>
        </div>
        <div className="actions">
          <button className="btn btn-danger text-white" onClick={() => handleModalOpen('mobile')}>Contribute for {combinedName}</button>
        </div>
      </div>
    </div>
  );
};

export default SharedPledgeCard;
