// Register.jsx

import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel, Button, Image, Row, Col, Modal, Spinner } from 'react-bootstrap';
import '../../assets/scss/Register.scss';
import { Link, useParams } from "react-router-dom";
import Citam from '../../assets/img/capstone_faith_transparent.png'
import Select from 'react-select'
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import PhoneNumberValidation from '../components/PhoneNumberValidation';
import TermsConditions from '../components/TermsConditions';
import PrivacyPolicy from '../components/PrivacyPolicy';

function Register() {
  let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
  let [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const { id } = useParams()
  const [formData, setFormData] = useState({
    name: "",
    idNumber: "",
    guest_church: "",
    phoneNumber: "",
    email: "",
    gender: "",
    membership: "",
    birhday: "",
    password: "",
    confirmPassword: "",
    selectedCountry: 'KE',
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [hasEight, setHasEight] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('weak');
  const [selectedGuest, setSelectedGuest] = useState('guest');

  const handleFormInput = (e) => {
    const { name, value } = e.target;
    // Check if the input is related to password or confirmPassword
    if (name === "password" || name === "confirmPassword") {
      // If passwords match, update state and set passwordsMatch to true
      if (name === "confirmPassword" && formData.password === value) {
        setPasswordsMatch(true);
      } else if (name === "confirmPassword") {
        // If passwords don't match, update state and set passwordsMatch to false
        setPasswordsMatch(false);
      }
    }

    // check if the password matches the criterias neede
    if (name === 'password') {
      const hasEight = value.length >= 8;
      setHasEight(hasEight);
      const hasNumbers = /[0-9]/.test(value);
      setHasNumbers(hasNumbers);
      const hasUppercase = /[A-Z]/.test(value);
      setHasUppercase(hasUppercase);
      const hasSpecialCharacter = /[!@#$%^&*(),.?"{}|<>]/.test(value);
      setHasSpecialCharacter(hasSpecialCharacter);
    }

    if (hasEight && hasNumbers && hasUppercase && hasSpecialCharacter) {
      setPasswordStrength('strong');
    } else if (hasEight && (hasNumbers || hasUppercase || hasSpecialCharacter)) {
      setPasswordStrength('fair');
    } else {
      setPasswordStrength('weak');
    }



    // check the if they have any value then change the status
    if (name === 'guest_church') {
      if (value !== '') {
        setSelectedGuest('guest');
        setFormData((prevData) => ({
          ...prevData,
          guest_church: "",
          disabledGuestChurch: false
        }));
      } else {
        setSelectedGuest('member');
        setFormData((prevData) => ({
          ...prevData,
          guest_church: "",
          disabledGuestChurch: true
        }));
      }
    }



    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (value, country, event) => {
    setFormData({
      ...formData,
      phoneNumber: value || '', // Update phoneNumber state with formatted value
    });

    // Extract name from the event if it exists
    const name = event ? event.target.name : '';
    // Check if name is properly extracted
  };
  const [campaignData, setCampaignData] = useState({})

  useEffect(() => {
    // get the campaign of that user
    serverUrl.get(`/pledge/giving-campaigns/`)
      .then((res) => {
        serverUrl.get(`/pledge/homepage-campaign-details/?campaign_id=${res.data.results[0].id}`)
          .then((res) => {
            setCampaignData(res.data.results[0])
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])





  const [page, setPage] = useState(1);
  // confirm if the passwords are
  const phone_number = localStorage.getItem('number')
  const country = localStorage.getItem('country')
  const handleNext = () => {
    // check if the user is already in the system
    if (page === 1) {
      setLoading(true)
      if (phone_number) {
        serverUrl.get(`user/get-user-in-system/?phone_number=${phone_number.toString()}`)
          .then((res) => {
            setLoading(false)
            if (res.data) {
              toast.error(res.data)
              setLoading(false)

            }
            else {
              setPage(page + 1);
              setLoading(false)

            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error.response.data)
            setLoading(false)


          })
      }
      else {
        toast.error('Phone Number is needed')
        setLoading(false)

      }

    }
    else {
      setPage(page + 1);

    }
  };
  const handleBack = () => {
    setPage(page - 1);
  };

  const [optionList, setOptionList] = useState(null);



  useEffect(() => {
    // const churchOptions = churchResults.map(church => ({
    //   value: church.id,
    //   label: church.name
    // }));
    // setOptionList(churchOptions);


    serverUrl.get(`/member/groups`)
      .then((res) => {
        // Map the filtered results to options
        const options = res.data.results.map(church => ({
          value: church.id,
          label: church.title
        }));

        // Set the options list
        setOptionList(options);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);


  const [selectedOptions, setSelectedOptions] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  };

  const yellowHappyFace = '\u{1F600}';
  const [setUserRegister, setShowRegister] = useState(null);
  const [newAccountData, setnewAccountData] = useState('')
  const [userLinkedCorprate, setuserLinkedCorprate] = useState('')
  const [corporateLinkedUser, setcorporateLinkedUser] = useState('')
  useEffect(() => {

    setnewAccountData(localStorage.getItem('newAccount'))
    setuserLinkedCorprate(localStorage.getItem('userLinked'))
    setcorporateLinkedUser(localStorage.getItem('corporateLinked'))
    setShowRegister(localStorage.getItem('newAccount'));


  }, []);

  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [showModalAcceptance, setShowModalAcceptance] = useState(false)

  const refusedTerms = () => {
    toast.error('Refused our terms and conditions')
  }

  const acceptedTermsRegister = () => {
    let userMember;
    if (formData.guest_church === '') {
      userMember = true
    }

    else if (formData.guest_church !== '') {
      userMember = false
    }

    const data = {
      email: formData.email,
      phone_number: '+' + phone_number,
      full_name: formData.fullName,
      password: formData.confirmPassword,
      is_member: userMember,
      country: country,
    };

    if (id) {
      data.account_type = id
    }
    if (formData.guest_church) {
      data.guest_church = formData.guest_church
    }
    if (setUserRegister) {
      data.account_type = setUserRegister
    }
    else {
      data.account_type = id

    }

    if (formData.gender && formData.gender.trim() !== '') {
      data.gender = formData.gender;
    }
    // register the user 
    if (!passwordsMatch) {
      toast.error('Passwords do not match ensure they do')
      return
    }
    setLoading(true)

    serverUrl.post(`/user/register/`, data)
      .then((res) => {
        const userId = res.data.data.id
        const phoneNumber = res.data.data.phone_number
        const password = formData.confirmPassword
        const fullName = formData.fullName

        localStorage.removeItem('number')
        localStorage.removeItem('help_requester')
        localStorage.removeItem('user')
        // if they select the member for assembly     
        if (userMember === true) {
          const formData = new FormData();
          formData.append('user', userId.toString());
          formData.append('church', 1);
          // add the member to the group too
          const formDataGroup = new FormData();
          formDataGroup.append('user', userId.toString());
          formDataGroup.append('group', selectedOptions.value);
          serverUrl.post(`/churches/add-user-to-group/`, formDataGroup)
            .then((res) => {

            })
            .catch((error) => {
              console.log(error)
            })

          serverUrl.post(`/churches/create-church-user/`, formData)
            .then((res) => {
              const loginData = {
                phone_number: phoneNumber,
                password: password,
              }
              serverUrl.post(`/user/login/`, loginData)
                .then((res) => {
                  const data = res.data.data
                  setAuthTokens(data.tokens)
                  setUser(jwtDecode(data.tokens.access))
                  localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                  localStorage.setItem('church', data.church_name)
                  localStorage.setItem('churchId', data.church_id)

                  toast.success(`Welcome to Capstone Faith Ministry campaign ${yellowHappyFace}`)
                  localStorage.removeItem('number')
                  localStorage.setItem('church', data.church_name)
                  localStorage.setItem('churchId', data.church_id)
                  setLoading(false)
                  if (newAccountData && userLinkedCorprate) {
                    // get the access of the user logged in
                    const config = {
                      headers: { Authorization: `Bearer ${data.tokens.access}` },
                    };
                    // the data to post fpor the user
                    const formDataLinked = new FormData();
                    formDataLinked.append('user', userLinkedCorprate.toString());
                    formDataLinked.append('corporate_user', userId.toString());
                    formDataLinked.append('corporate_name', fullName);
                    // post the data live
                    serverUrl.post(`/user/corporates/`, formDataLinked, config)
                      .then((res) => {
                        localStorage.setItem('authTokensAccount', JSON.stringify(data.tokens))
                        navigate('/home')
                        localStorage.removeItem('newAccount')
                        localStorage.removeItem('userLinked')

                      })
                      .catch((error) => {
                        console.log(error)
                      })

                  }
                  // save the ocrporate data with the linked user
                  else if (newAccountData && corporateLinkedUser) {
                    // get the access of the user logged in
                    const config = {
                      headers: { Authorization: `Bearer ${data.tokens.access}` },
                    };
                    // the data to post fpor the user
                    const formDataLinked = new FormData();
                    formDataLinked.append('user', userId.toString());
                    formDataLinked.append('corporate_user', corporateLinkedUser.id.toString());
                    formDataLinked.append('corporate_name', corporateLinkedUser.fullName);
                    // post the data live
                    serverUrl.post(`/user/corporates/`, formDataLinked, config)
                      .then((res) => {
                        localStorage.setItem('authTokensAccount', JSON.stringify(data.tokens))
                        navigate('/home')
                        localStorage.removeItem('newAccount')
                        localStorage.removeItem('corporateLinked')

                      })
                      .catch((error) => {
                        console.log(error)
                      })

                  }
                  else {
                    navigate('/home')

                  }



                })
                .catch((error) => {
                  console.log(error)
                })
            })
            .catch((error) => {
              console.log(error)
            })
        }
        else {
          const loginData = {
            phone_number: phoneNumber,
            password: password,
          }

          serverUrl.post(`/user/login/`, loginData)
            .then((res) => {
              const data = res.data.data
              setAuthTokens(data.tokens)
              setUser(jwtDecode(data.tokens.access))
              localStorage.setItem('authTokens', JSON.stringify(data.tokens))
              toast.success(`Welcome to Capstone Faith Ministry campaign ${yellowHappyFace}`)
              const phone_number = localStorage.removeItem('number')
              localStorage.setItem('church', data.church_name)
              localStorage.setItem('churchId', data.church_id)
              setLoading(false)

              // confirm if the user is individual and is from swicthing account
              if (newAccountData && userLinkedCorprate) {
                // get the access of the user logged in
                const config = {
                  headers: { Authorization: `Bearer ${data.tokens.access}` },
                };
                // the data to post fpor the user
                const formDataLinked = new FormData();
                formDataLinked.append('user', userLinkedCorprate.toString());
                formDataLinked.append('corporate_user', userId.toString());
                formDataLinked.append('corporate_name', fullName);
                // post the data live
                serverUrl.post(`/user/corporates/`, formDataLinked, config)
                  .then((res) => {
                    localStorage.setItem('authTokensAccount', JSON.stringify(data.tokens))
                    navigate('/home')
                    localStorage.removeItem('newAccount')
                    localStorage.removeItem('userLinked')

                  })
                  .catch((error) => {
                    console.log(error)
                  })

              }
              // save the ocrporate data with the linked user
              else if (newAccountData && corporateLinkedUser) {
                // get the access of the user logged in
                const config = {
                  headers: { Authorization: `Bearer ${data.tokens.access}` },
                };
                // the data to post fpor the user
                const formDataLinked = new FormData();
                formDataLinked.append('user', userId.toString());
                formDataLinked.append('corporate_user', corporateLinkedUser.id.toString());
                formDataLinked.append('corporate_name', corporateLinkedUser.fullName);
                // post the data live
                serverUrl.post(`/user/corporates/`, formDataLinked, config)
                  .then((res) => {
                    localStorage.setItem('authTokensAccount', JSON.stringify(data.tokens))
                    navigate('/home')
                    localStorage.removeItem('newAccount')
                    localStorage.removeItem('corporateLinked')

                  })
                  .catch((error) => {
                    console.log(error)
                  })

              }
              else {
                navigate('/home')

              }


            })
            .catch((error) => {
              console.log(error)
              setLoading(false)

            })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error('User with similar data already exists');
          setLoading(false)

        }
        else if (error.response && error.response.status === 400) {
          const message = error.response.data.data.error;
          toast.error(message);
          setLoading(false)

        } else {
          console.log(error);
          setLoading(false)

        }
      })




  };

  const handlRegister = (e) => {
    e.preventDefault();

    // Check if the terms are accepted or not then register the user
    setShowModalAcceptance(true);


    // Validate if passwords match
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match. Please ensure they match.');
      return;
    }

    if (!phone_number || !formData.fullName) {
      toast.error('Phone number and full name are required fields.');
      return;
    }

  };
  const memberButtonClass = selectedGuest !== 'member' ? 'btn w-50 border-info bg-transparent text-dark' : 'btn w-50 text-white btn-danger border-0';



  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const daysOptions = () => {
    const days = [];
    const { birthMonth, birthYear } = formData;
    if (birthMonth !== '' && birthYear !== '') {
      const numDays = getDaysInMonth(parseInt(birthMonth), parseInt(birthYear));
      for (let i = 1; i <= numDays; i++) {
        days.push(<option key={i} value={i}>{i}</option>);
      }
    }
    return days;
  };


  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleTogglePasswordConfirm = () => {
    setShowPasswordConfirm((prevShowPassword) => !prevShowPassword);
  };


  const handleToggleRegister = (user) => {
    setShowRegister(user);
  };




  const handleChangeGuest = () => {
    setSelectedGuest(selectedGuest === 'guest' ? 'member' : 'guest');

  };


  const handleGenderSelection = (selectedGender) => {
    setFormData({ ...formData, gender: selectedGender });
  };

  // modal to display the terms sand conditions
  const [showModalTerms, setShowModalTerms] = useState(false);

  const handleOpenModalTerms = () => {
    setShowModalTerms(true);
  };

  const handleCloseModalTerms = () => {
    setShowModalTerms(false);
  };

  // modal to display the terms sand conditions
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);

  const handleOpenModalPrivacy = () => {
    setShowModalPrivacy(true);
  };

  const handleCloseModalPrivacy = () => {
    setShowModalPrivacy(false);
  };

  const goBack = () => {
    window.history.back();
    setShowRegister(null)
    setPage(1)

  };
  const goBackRegister = () => {
    setShowRegister(null)
    setPage(1)

  };

  return (
    <div className='mainRegister'>
      <div className='RegisterPage'>
        {/* Add more bubbles as needed */}
        <Image src={Citam} style={{ width: "5%" }} />
        <Row>
          <div className='col-6'>
            {/* Login form */}
            {setUserRegister ?
              <div className='d-flex'>
                <p onClick={goBackRegister} className='topNameDisplayRegister'>
                  <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem" }}></i>
                </p>
                <h2 className='topNameDisplayRegister'>Register as {setUserRegister}</h2>

              </div>
              :

              <h2 className='topNameDisplayRegister' style={{ marginLeft: "8rem" }}>Register</h2>

            }

            <Form className='loginForm' onSubmit={handlRegister}>
              {setUserRegister === null ?
                <>
                  <p className='chooseButtonRegister'>


                  </p>
                  <div className='registerIndividual'>

                    <Button onClick={() => handleToggleRegister('individual')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'individual' ? 'ActiveButton' : ''}`}>
                      <i className='bi bi-person text-danger me-2'></i>
                      An individual</Button>
                    <Button onClick={() => handleToggleRegister('family')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'family' ? 'ActiveButton' : ''}`}>
                      <i class="bi bi-people text-danger me-2"></i>
                      A Family</Button>

                    <Button onClick={() => handleToggleRegister('corporate')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'corporate' ? 'ActiveButton' : ''}`}>
                      <i class="bi bi-building text-danger me-2"></i>
                      A corporate</Button>


                  </div>
                  <Link to='/login'>Already have an account? <u>Login</u></Link>

                </>
                : setUserRegister === 'corporate' ?
                  <>
                    {page === 1 && (
                      <>
                        <div className='d-flex align-items-center'>
                          <div className="progress">
                            <div className="progress-fill" style={{ width: `50%` }} />
                          </div>
                          <span className='ms-1 pageCounter'>Page 1 of 2</span>
                        </div>
                        <label>Company Name <span className='text-danger mt-2'>*</span> </label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          placeholder=""
                          value={formData.fullName}
                          onChange={handleFormInput}
                          required
                        />

                        <label>Phone Number <span className='text-danger mt-2'>*</span> </label>
                        <PhoneNumberValidation />

                        <label>Email</label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleFormInput}
                        />
                        {loading ?
                          <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                          :
                          <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                        }
                      </>
                    )}

                    {page === 2 && (
                      <>
                        <div className='d-flex align-items-center'>
                          <div className="progress">
                            <div className="progress-fill" style={{ width: `67%` }} />
                          </div>
                          <span className='ms-1 pageCounter'>Page 2 of 3</span>
                        </div>

                        <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>
                        <br></br>
                        <div className='selectChurch'>
                        <Select
                          options={optionList}
                          placeholder="Select Group"
                          value={selectedOptions}
                          onClick={() => handleChangeGuest('member')}
                          onChange={handleSelectChange}
                          className='church-select'
                        />
                      </div>
                      
                      <div className='d-flex justify-content-between'>
                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                        <p>OR</p>

                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                      </div>

                        <label>If am a guest (Please Indicate Church)</label>

                        <Form.Control
                          type="text"
                          name="guest_church"
                          value={formData.guest_church}
                          onChange={handleFormInput}
                          disabled={formData.disabledGuestChurch}

                        />



                        <div className='d-flex'>
                          <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                          {loading ?
                            <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                            :
                            <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                          }                        </div>
                        <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>

                      </>
                    )}


                    {page === 3 && (
                      <>
                        <div className='d-flex align-items-center'>
                          <div className="progress">
                            <div className="progress-fill" style={{ width: `100%` }} />
                          </div>
                          <span className='ms-1 pageCounter'>Page 3 of 3</span>
                        </div>
                        <label>Password</label>
                        <div className='d-flex'>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            onChange={handleFormInput}
                          />
                          <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
                            {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                          </span>
                        </div>


                        <label>Confirm Pasword</label>
                        <div className='d-flex'>
                          <Form.Control
                            type={showPasswordConfirm ? "text" : "password"}
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleFormInput}
                          />
                          <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePasswordConfirm}>
                            {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                          </span>
                        </div>
                        {!passwordsMatch && (
                          <p className='text-danger'>Passwords don't match</p>
                        )}





                        <div className='d-flex'>
                          <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                          <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>
                        </div>
                        <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>
                      </>
                    )}

                    <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                      <Modal.Header closeButton>
                        <Modal.Title>Terms And Conditions</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className='termsandConditions'>
                        <TermsConditions />
                      </Modal.Body>
                      <Modal.Footer>

                        <Button variant="success">
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>


                    <Link to='/login'>Already have an account? <u>Login</u></Link>

                  </>
                  : setUserRegister === 'individual' ?
                    <>
                      {page === 1 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `33%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 1 of 3</span>
                          </div>
                          <label>Full Name <span className='text-danger mt-2'>*</span></label>
                          <Form.Control
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            required
                            onChange={handleFormInput}
                          />

                          <label>Phone Number <span className='text-danger mt-2'>*</span> </label>
                          <PhoneNumberValidation />


                          <label>Gender</label>
                          <div className='d-flex justify-content-between' style={{ width: "75%" }}>
                            <Button
                              className={formData.gender === 'male' ? 'ActiveButton' : 'btn btn-sm bg-transparent border-danger text-danger'}
                              style={{ width: "10vw", borderRadius: "10px" }}
                              onClick={() => handleGenderSelection('male')}
                            >
                              Male
                            </Button>
                            <Button
                              className={formData.gender === 'female' ? 'ActiveButton' : 'btn btn-sm bg-transparent border-danger text-danger'}
                              style={{ width: "10vw", borderRadius: "10px" }}
                              onClick={() => handleGenderSelection('female')}
                            >
                              Female
                            </Button>
                          </div>
                          {loading ?
                            <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                            :
                            <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                          }

                        </>
                      )}

                      {page === 2 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `66%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 2 of 3</span>
                          </div>


                          <label>Email</label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleFormInput}
                          />
                          <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>
                          <br></br>
                          <div className='selectChurch'>
                            <Select
                              options={optionList}
                              placeholder="Select Group"
                              value={selectedOptions}
                              onClick={() => handleChangeGuest('member')}
                              onChange={handleSelectChange}
                              className='church-select'
                            />
                          </div>

                          <div className='d-flex justify-content-between'>
                            <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                            <p>OR</p>

                            <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                          </div>

                          <label>If am a guest (Please Indicate Church)</label>
                          <Form.Control
                            type="text"
                            name="guest_church"
                            value={formData.guest_church}
                            onChange={handleFormInput}
                            disabled={formData.disabledGuestChurch}

                          />


                          <div className='d-flex'>
                            <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>

                            {loading ?
                              <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                              :
                              <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                            }
                          </div>
                        </>
                      )}

                      {page === 3 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `100%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 3 of 3</span>
                          </div>


                          <label>Password <span className='text-danger mt-2'>*</span></label>
                          <div className='d-flex'>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={formData.password}
                              onChange={handleFormInput}
                            />
                            <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
                              {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                            </span>
                          </div>
                          {formData.password &&
                            <div className='passwordValidation'>
                              <div className='d-flex justify-content-between'>
                                <p>For a stronger password: </p>

                              </div>
                              {hasEight ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has more than eight characters
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has more than eight characters

                                </p>
                              }

                              {hasNumbers ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has Number [0-9]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has Number [0-9]
                                </p>
                              }

                              {hasUppercase ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has Uppercase letter [A-Z]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has Uppercase letter [A-Z]
                                </p>
                              }

                              {hasSpecialCharacter ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has a special character [!@#$%^&*(),.?"{ }|]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has a special character [!@#$%^&*(),.?"{ }|]

                                </p>
                              }

                            </div>
                          }


                          <label>Confirm Pasword <span className='text-danger mt-2'>*</span></label>
                          <div className='d-flex'>
                            <Form.Control
                              type={showPasswordConfirm ? "text" : "password"}
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleFormInput}
                            />
                            <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePasswordConfirm}>
                              {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                            </span>
                          </div>

                          {!passwordsMatch && (
                            <p className='text-danger'>Passwords don't match</p>
                          )}

                          <div className='d-flex'>
                            <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                            <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>
                          </div>
                          <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>
                        </>
                      )}


                      <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                        <Modal.Header closeButton>
                          <Modal.Title>Terms And Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='termsandConditions'>
                          <TermsConditions />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="success">
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Link to='/login'>Already have an account? <u>Login</u></Link>

                    </>

                    :
                    <>
                      {page === 1 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `33%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 1 of 3</span>
                          </div>
                          <label>Family Name <span className='text-danger mt-2'>*</span></label>
                          <Form.Control
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleFormInput}
                          />

                          <label>Family Phone Number <span className='text-danger mt-2'>*</span> </label>
                          <PhoneNumberValidation />

                          <label>Family Email</label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleFormInput}
                          />


                          {loading ?
                            <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                            :
                            <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                          }
                        </>
                      )}

                      {page === 2 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `66%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 2 of 3</span>
                          </div>


                          <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>

                          <br></br>
                          <div className='selectChurch'>
                            <Select
                              options={optionList}
                              placeholder="Select Group"
                              value={selectedOptions}
                              onClick={() => handleChangeGuest('member')}
                              onChange={handleSelectChange}
                              className='church-select'
                            />
                          </div>

                          <div className='d-flex justify-content-between'>
                            <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                            <p>OR</p>

                            <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                          </div>

                          <label>If am a guest (Please Indicate Church)</label>
                          <Form.Control
                            type="text"
                            name="guest_church"
                            value={formData.guest_church}
                            onChange={handleFormInput}
                            disabled={formData.disabledGuestChurch}

                          />


                          <div className='d-flex'>
                            <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>

                            {loading ?
                              <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                              :
                              <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                            }
                          </div>
                        </>
                      )}

                      {page === 3 && (
                        <>
                          <div className='d-flex align-items-center'>
                            <div className="progress">
                              <div className="progress-fill" style={{ width: `100%` }} />
                            </div>
                            <span className='ms-1 pageCounter'>Page 3 of 3</span>
                          </div>

                          <label>Password <span className='text-danger mt-2'>*</span></label>
                          <div className='d-flex'>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={formData.password}
                              onChange={handleFormInput}
                            />
                            <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
                              {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                            </span>
                          </div>

                          {formData.password &&
                            <div className='passwordValidation'>
                              <div className='d-flex justify-content-between'>
                                <p>For a stronger password: </p>

                              </div>                              {hasEight ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has more than eight characters
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has more than eight characters

                                </p>
                              }

                              {hasNumbers ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has Number [0-9]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has Number [0-9]
                                </p>
                              }

                              {hasUppercase ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has Uppercase letter [A-Z]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has Uppercase letter [A-Z]
                                </p>
                              }

                              {hasSpecialCharacter ?
                                <p className='' style={{ color: "#2ecc71" }}>
                                  <i class="bi bi-check-circle-fill me-2"></i>
                                  Has a special character [!@#$%^&*(),.?"{ }|]
                                </p>
                                :
                                <p className='' style={{ color: "red" }}>
                                  <i class="bi bi-x-circle-fill me-2"></i>
                                  Has a special character [!@#$%^&*(),.?"{ }|]

                                </p>
                              }

                            </div>
                          }

                          <label>Confirm Pasword <span className='text-danger mt-2'>*</span></label>
                          <div className='d-flex'>
                            <Form.Control
                              type={showPasswordConfirm ? "text" : "password"}
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleFormInput}
                            />
                            <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePasswordConfirm}>
                              {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                            </span>
                          </div>

                          {!passwordsMatch && (
                            <p className='text-danger'>Passwords don't match</p>
                          )}



                          <div className='d-flex'>
                            <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                            {loading ?
                              <Button className='btn btn-danger border-0 text-white' type='button'>
                                <Spinner />
                              </Button>

                              :
                              <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>

                            }
                          </div>
                          <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>
                        </>
                      )}
                      <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                        <Modal.Header closeButton>
                          <Modal.Title>Terms And Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='termsandConditions'>
                          <TermsConditions />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="success">
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>


                      <Link to='/login'>Already have an account? <u>Login</u></Link>

                    </>

              }
            </Form>


          </div>

          <div className='col-6'>

            <div className='curvedSpan' >
              <div className='textDisplay'>
                <div className='InnerDivDisplay'>
                  <h2>{campaignData.campaing_about}</h2>

                  <div className='displayParagraph'>
                    <br></br>
                    <p>                {campaignData.campaing_description}                            </p>

                    <p><b>Register now</b> to gain access to exclusive updates, engage in fundraising events, and play a vital role in  our Capstone Faith Ministry campaign.</p>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </Row>
      </div>



      <div className='RegisterPageMobile'>
        <div className='d-flex justify-content-between'>
          <p onClick={goBack}>
            <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem" }}></i>
          </p>

          <Image src={Citam} />
        </div>
        {/* Floating bubbles */}
        <figure class="bubble6"></figure>
        <figure class="bubble5"></figure>

        {/* Add more bubbles as needed */}

        {/* Login form */}

        <Form className='loginForm d-block' onSubmit={handlRegister}>
          {!id ?
            <>
              <h2 style={{ marginLeft: "0rem", textAlign: "left", marginTop: "4rem" }}>Register</h2>


              <p className='chooseButtonRegister'>
                {/* Choose to be acknowledged as an individual, family or a corporate. */}
              </p>
              <div className='justify-content-between' style={{ height: "5vh" }}>
                <div>
                  <Link to='/register/individual' className='text-decoration-none'>
                    <Button style={{ width: "35vw", height: "6vh", marginRight: "1.8rem" }} onClick={() => handleToggleRegister('individual')} className={`btn btn-sm border-danger mb-2  InactiveButton bg-transparent ${setUserRegister === 'individual' ? 'ActiveButton' : 'text-danger'}`}>
                      <i className='bi bi-person text-danger me-2'></i>
                      Individual</Button>
                  </Link>

                  <Link to='/register/family'>
                    <Button style={{ width: "35vw", height: "6vh" }} onClick={() => handleToggleRegister('family')} className={`btn btn-sm border-danger mb-2  InactiveButton bg-transparent ${setUserRegister === 'family' ? 'ActiveButton' : 'text-danger'}`}>
                      <i class="bi bi-people text-danger me-2"></i>
                      Family</Button>
                  </Link>
                </div>
                <Link to='/register/corporate' className='text-decoration-none'>
                  <Button style={{ marginLeft: "0rem", height: "6vh" }} onClick={() => handleToggleRegister('corporate')} className={`btn btn-sm border-danger  InactiveButton bg-transparent ${setUserRegister === 'corporate' ? 'ActiveButton' : 'text-danger'}`}>
                    <i class="bi bi-building text-danger me-2"></i>
                    Corporate</Button>
                </Link>
              </div>

            </>
            : id === 'corporate' ?
              <>
                <h2 style={{ marginLeft: "0rem", textAlign: "left", marginBottom: "1rem" }}>Register As A Corporate</h2>

                {page === 1 && (
                  <>
                    <div className='d-flex align-items-center'>
                      <div className="progress">
                        <div className="progress-fill" style={{ width: `33%` }} />
                      </div>
                      <span className='ms-1 pageCounter'>Page 1 of 3</span>
                    </div>
                    <label>Company Name <span className='text-danger mt-2'>*</span> </label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder=""
                      value={formData.fullName}
                      onChange={handleFormInput}
                    />

                    <label>Phone Number <span className='text-danger mt-2'>*</span> </label>
                    <PhoneNumberValidation />

                    <label>Email</label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleFormInput}
                    />
                    {loading ?
                      <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                      :
                      <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                    }

                  </>
                )}

                {page === 2 && (
                  <>

                    <div className='d-flex align-items-center'>
                      <div className="progress">
                        <div className="progress-fill" style={{ width: `67%` }} />
                      </div>
                      <span className='ms-1 pageCounter'>Page 2 of 3</span>
                    </div>

                    <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>
                    <div className='selectChurch'>
                      <Select
                        options={optionList}
                        placeholder="Select Group"
                        value={selectedOptions}
                        onClick={() => handleChangeGuest('member')}
                        onChange={handleSelectChange}
                        className='church-select'
                      />
                    </div>

                    <br></br>

                    <div className='d-flex justify-content-between'>
                      <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                      <p>OR</p>

                      <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                    </div>


                    <label>If am a guest (Please Indicate Church)</label>
                    <Form.Control
                      type="text"
                      name="guest_church"
                      value={formData.guest_church}
                      onChange={handleFormInput}
                      disabled={formData.disabledGuestChurch}

                    />


                    <div className='d-flex'>
                      <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>

                      {loading ?
                        <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                        :
                        <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                      }                    </div>

                  </>
                )}

                {page === 3 && (
                  <>
                    <div className='d-flex align-items-center'>
                      <div className="progress">
                        <div className="progress-fill" style={{ width: `100%` }} />
                      </div>
                      <span className='ms-1 pageCounter'>Page 3 of 3</span>
                    </div>
                    <label>Password</label>

                    <div className='d-flex'>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleFormInput}
                      />
                      <span style={{ cursor: "pointer", marginTop: "2rem" }} onClick={handleTogglePassword}>
                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                      </span>
                    </div>

                    {formData.password &&
                      <div className='passwordValidation'>
                        <div className='d-flex justify-content-between'>
                          <p>For a stronger password: </p>

                        </div>                        {hasEight ?
                          <p className='' style={{ color: "#2ecc71" }}>
                            <i class="bi bi-check-circle-fill me-2"></i>
                            Has more than eight characters
                          </p>
                          :
                          <p className='' style={{ color: "red" }}>
                            <i class="bi bi-x-circle-fill me-2"></i>
                            Has more than eight characters

                          </p>
                        }

                        {hasNumbers ?
                          <p className='' style={{ color: "#2ecc71" }}>
                            <i class="bi bi-check-circle-fill me-2"></i>
                            Has Number [0-9]
                          </p>
                          :
                          <p className='' style={{ color: "red" }}>
                            <i class="bi bi-x-circle-fill me-2"></i>
                            Has Number [0-9]
                          </p>
                        }

                        {hasUppercase ?
                          <p className='' style={{ color: "#2ecc71" }}>
                            <i class="bi bi-check-circle-fill me-2"></i>
                            Has Uppercase letter [A-Z]
                          </p>
                          :
                          <p className='' style={{ color: "red" }}>
                            <i class="bi bi-x-circle-fill me-2"></i>
                            Has Uppercase letter [A-Z]
                          </p>
                        }

                        {hasSpecialCharacter ?
                          <p className='' style={{ color: "#2ecc71" }}>
                            <i class="bi bi-check-circle-fill me-2"></i>
                            Has a special character [!@#$%^&*(),.?"{ }|]
                          </p>
                          :
                          <p className='' style={{ color: "red" }}>
                            <i class="bi bi-x-circle-fill me-2"></i>
                            Has a special character [!@#$%^&*(),.?"{ }|]

                          </p>
                        }

                      </div>
                    }

                    <label>Confirm Pasword</label>
                    <div className='d-flex'>
                      <Form.Control
                        type={showPasswordConfirm ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleFormInput}
                      />
                      <span style={{ cursor: "pointer", marginTop: "2rem" }} onClick={handleTogglePasswordConfirm}>
                        {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                      </span>
                    </div>

                    {!passwordsMatch && (
                      <p className='text-danger'>Passwords don't match</p>
                    )}



                    <div className='d-flex'>
                      <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                      <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>
                    </div>
                    <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>
                  </>
                )}

                <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                  <Modal.Header closeButton>
                    <Modal.Title>Terms And Conditions</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='termsandConditions'>
                    <TermsConditions />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success">
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

              </>
              : id === 'individual' ?
                <>

                  <h2 style={{ marginLeft: "0rem", textAlign: "left", marginBottom: "1rem" }}>Register As An Individual</h2>

                  {page === 1 && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `33%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 1 of 3</span>
                      </div>
                      <label>Full Name <span className='text-danger mt-2'>*</span></label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleFormInput}
                      />

                      <label>Phone Number <span className='text-danger mt-2'>*</span> </label>
                      <PhoneNumberValidation />

                      <label>Email <b className='text-danger mt-2'>*</b></label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleFormInput}
                      />

                      {loading ?
                        <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                        :
                        <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                      }
                    </>
                  )}

                  {page === 2 && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `66%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 2 of 3</span>
                      </div>


                      <label>Gender</label>
                      <div className='d-flex justify-content-around'>
                        <Button
                          className={formData.gender === 'male' ? 'ActiveButton' : 'btn btn-sm bg-transparent border-secondary text-danger'}
                          style={{ width: "38vw", borderRadius: "10px", height: "auto", padding: "0.5rem" }}
                          onClick={() => handleGenderSelection('male')}
                        >
                          Male
                        </Button>
                        <Button
                          className={formData.gender === 'female' ? 'ActiveButton' : 'btn btn-sm bg-transparent border-secondary text-danger'}
                          style={{ width: "38vw", borderRadius: "10px", height: "auto", padding: "0.5rem" }}
                          onClick={() => handleGenderSelection('female')}
                        >
                          Female
                        </Button>
                      </div>
                      <br></br>
                      <br></br>

                      <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>
                      <div className='selectChurch'>
                        <Select
                          options={optionList}
                          placeholder="Select Group"
                          value={selectedOptions}
                          onClick={() => handleChangeGuest('member')}
                          onChange={handleSelectChange}
                          className='church-select'
                        />
                      </div>

                      <br></br>

                      <div className='d-flex justify-content-between'>
                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                        <p>OR</p>

                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                      </div>


                      <label className='mt-2' >If Guest (Please Indicate Church)</label>
                      <Form.Control
                        type="text"
                        name="guest_church"
                        value={formData.guest_church}
                        onChange={handleFormInput}
                        disabled={formData.disabledGuestChurch}

                      />

                      <div className='d-flex'>
                        <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>

                        {loading ?
                          <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                          :
                          <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                        }
                      </div>
                    </>
                  )}

                  {page === 3 && (
                    <>
                      <div className='d-flex align-items-center' style={{ marginBottom: "1rem" }}>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `100%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 3 of 3</span>
                      </div>


                      <label>Password <span className='text-danger mt-2'>*</span></label>
                      <div className='d-flex'>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          onChange={handleFormInput}
                        />
                        <span style={{ cursor: "pointer", marginTop: "2rem" }} onClick={handleTogglePassword}>
                          {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                        </span>
                      </div>

                      {formData.password &&
                        <div className='passwordValidation'>
                          <div className='d-flex justify-content-between'>
                            <p>For a stronger password: </p>

                          </div>                          {hasEight ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has more than eight characters
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has more than eight characters

                            </p>
                          }

                          {hasNumbers ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has Number [0-9]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has Number [0-9]
                            </p>
                          }

                          {hasUppercase ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has Uppercase letter [A-Z]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has Uppercase letter [A-Z]
                            </p>
                          }

                          {hasSpecialCharacter ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has a special character [!@#$%^&*(),.?"{ }|]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has a special character [!@#$%^&*(),.?"{ }|]

                            </p>
                          }

                        </div>
                      }

                      <label>Confirm Pasword <span className='text-danger mt-2'>*</span></label>
                      <div className='d-flex'>
                        <Form.Control
                          type={showPasswordConfirm ? "text" : "password"}
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleFormInput}
                        />
                        <span style={{ cursor: "pointer", marginTop: "2rem" }} onClick={handleTogglePasswordConfirm}>
                          {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                        </span>
                      </div>

                      {!passwordsMatch && (
                        <p className='text-danger'>Passwords don't match</p>
                      )}

                      <div className='d-flex'>
                        <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                        <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>
                      </div>
                      <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>
                    </>
                  )}
                  <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                    <Modal.Header closeButton>
                      <Modal.Title>Terms And Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='termsandConditions'>
                      <TermsConditions />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="success">
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
                :
                <>
                  <h2 style={{ marginLeft: "0rem", textAlign: "left", marginBottom: "1rem" }}>Register As A Family</h2>

                  {page === 1 && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `33%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 1 of 3</span>
                      </div>
                      <label>Family Name <span className='text-danger mt-2'>*</span></label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleFormInput}
                      />

                      <label>Family Phone Number <span className='text-danger mt-2'>*</span> </label>
                      <PhoneNumberValidation />

                      <label>Family Email</label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleFormInput}
                      />


                      {loading ?
                        <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                        :
                        <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                      }
                    </>
                  )}

                  {page === 2 && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `67%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 2 of 3</span>
                      </div>

                      <label style={{ marginTop: "1.2rem" }}>CFM Groups</label>
                      <div className='selectChurch'>
                        <Select
                          options={optionList}
                          placeholder="Select Group"
                          value={selectedOptions}
                          onClick={() => handleChangeGuest('member')}
                          onChange={handleSelectChange}
                          className='church-select'
                        />
                      </div>

                      <div className='d-flex justify-content-between'>
                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                        <p>OR</p>

                        <span style={{ borderBottom: "1px solid lightgray", width: "18vw", height: "2vh", marginInline: "0.5rem" }}></span>
                      </div>


                      <label>If am a guest (Please Indicate Church)</label>
                      <Form.Control
                        type="text"
                        name="guest_church"
                        value={formData.guest_church}
                        onChange={handleFormInput}
                        disabled={formData.disabledGuestChurch}

                      />

                      <div className='d-flex'>
                        <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>

                        {loading ?
                          <Button className='btn btn-danger border-0 btn-muted text-white'><Spinner /> </Button>
                          :
                          <Button className='btn btn-danger border-0 text-white' onClick={handleNext}>Next</Button>
                        }
                      </div>

                    </>
                  )}

                  {page === 3 && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div className="progress">
                          <div className="progress-fill" style={{ width: `100%` }} />
                        </div>
                        <span className='ms-1 pageCounter'>Page 3 of 3</span>
                      </div>


                      <label>Password <span className='text-danger mt-2'>*</span></label>
                      <div className='d-flex'>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={formData.password}
                          onChange={handleFormInput}
                        />
                        <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePassword}>
                          {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                        </span>
                      </div>

                      {formData.password &&
                        <div className='passwordValidation'>
                          <div className='d-flex justify-content-between'>
                            <p>For a stronger password: </p>

                          </div>                          {hasEight ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has more than eight characters
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has more than eight characters

                            </p>
                          }

                          {hasNumbers ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has Number [0-9]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has Number [0-9]
                            </p>
                          }

                          {hasUppercase ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has Uppercase letter [A-Z]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has Uppercase letter [A-Z]
                            </p>
                          }

                          {hasSpecialCharacter ?
                            <p className='' style={{ color: "#2ecc71" }}>
                              <i class="bi bi-check-circle-fill me-2"></i>
                              Has a special character [!@#$%^&*(),.?"{ }|]
                            </p>
                            :
                            <p className='' style={{ color: "red" }}>
                              <i class="bi bi-x-circle-fill me-2"></i>
                              Has a special character [!@#$%^&*(),.?"{ }|]

                            </p>
                          }

                        </div>
                      }

                      <label>Confirm Pasword <span className='text-danger mt-2'>*</span></label>
                      <div className='d-flex'>
                        <Form.Control
                          type={showPasswordConfirm ? "text" : "password"}
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleFormInput}
                        />
                        <span style={{ cursor: "pointer", marginTop: "0.5rem" }} onClick={handleTogglePasswordConfirm}>
                          {showPasswordConfirm ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", marginTop: "1rem" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem" }} />}
                        </span>
                      </div>

                      {!passwordsMatch && (
                        <p className='text-danger'>Passwords don't match</p>
                      )}

                      <div className='d-flex'>
                        <span className='spanarrow' onClick={handleBack}><i className='bi bi-chevron-left'></i></span>
                        {loading ?
                          <Button className='btn btn-danger border-0 text-white' type='button'>
                            <Spinner />
                          </Button>

                          :
                          <Button className='btn btn-danger border-0 text-white' type='submit'>Register</Button>

                        }
                      </div>
                      <p style={{ fontSize: "12px" }}>By creating an account, you are agreeing to our  <b className='text-danger' onClick={handleOpenModalTerms}>Terms & Condition</b>and <b style={{ color: "#90ABD4" }} onClick={handleOpenModalPrivacy}>Privacy Policy</b></p>



                    </>
                  )}



                  <Modal show={showModalTerms} onHide={handleCloseModalTerms} size='lg'>
                    <Modal.Header closeButton>
                      <Modal.Title>Terms And Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='termsandConditions'>
                      <TermsConditions />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="success">
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>


                </>

          }
          {/* <Link to='/login'>Already have an account? <u>Login</u></Link> */}

          {/* <div className='bottomRegister'>
            <Link to='' style={{ lineHeight: "0", marginTop: "10px" }}>Already have an account? </Link>

            <Link to='/login' >
              <Button className='btn btn-dark text-white'>LOGIN</Button>

            </Link>
          </div> */}
        </Form>


      </div>

      {/* accept terms and conditions */}
      <Modal show={showModalAcceptance} onHide={() => setShowModalAcceptance(false)} centered size='l'>
        <Modal.Header closeButton>
          <Modal.Title>Do you accept our terms and conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>By clicking yes it means that you are accepting our terms and conditions and that you have read the privacy policy</p>
          <div className='d-flex justify-content-around'>
            <Button variant="success" onClick={(e) => { setAcceptedTerms(true); acceptedTermsRegister(e); setShowModalAcceptance(false) }}>
              Yes
            </Button>
            <Button variant="warning" onClick={() => { setAcceptedTerms(false); setShowModalAcceptance(false); refusedTerms() }}>
              No
            </Button>
          </div>

        </Modal.Body>

      </Modal>



      <Modal show={showModalPrivacy} onHide={handleCloseModalPrivacy} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "70vh", overflow: "scroll" }}>
            <PrivacyPolicy />

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseModalPrivacy}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Register;
