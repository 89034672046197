import React, { useEffect, useState } from 'react'
import '../../assets/scss/LandingPage.scss'
import Family from '../../assets/img/capstone_welcome_screen.jpg'
import { Button, Image } from 'react-bootstrap'
import { Link, useParams } from "react-router-dom";
import Crown from '../../assets/img/crown.png'
import Arrow from '../../assets/img/arrow-circle.png'
import Logo from '../../assets/img/capstone_faith_transparent.png'
import PlaceHolder from '../../assets/img/capstone_image_loader.png'
import GooglePlay from '../../assets/img/googleplay.png'
import Appstore from '../../assets/img/appstore.png'
import { serverUrl } from '../../serverUrl';

function SharedCampaign() {
    const svgStyle = {
        backgroundImage: `url(${Family})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '40vw', // Adjust as needed
        height: '100vh', // Adjust as needed
        border: 'none',
    };
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const [setUserRegister, setShowRegister] = useState(null);

    const handleToggleRegister = (user) => {
        setShowRegister(user);
    };

    // campaign details
    const [campaignData, setCampaignData] = useState({})
    useEffect(() => {
        // get the campaign of that user
        serverUrl.get(`/pledge/giving-campaigns/`)
          .then((res) => {
            serverUrl.get(`/pledge/homepage-campaign-details/?campaign_id=${res.data.results[0].id}`)
              .then((res) => {
                setCampaignData(res.data.results[0])
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
    
      }, [])
    const formatAmount = (amount) => {
        if (amount >= 1000000000) {
            return (amount / 1000000000).toFixed(2) + 'B';
        } else if (amount >= 1000000) {
            return (amount / 1000000).toFixed(2) + 'M';
        } else if (amount >= 1000) {
            return (amount / 1000).toFixed(2) + 'K';
        } else {
            return amount;
        }
    };
    return (
        <>


            <div className='mobilelanding'>
                <Image src={Logo} className='logo' />

                <div className='mainImage'>
                    {campaignData.campaign_image ?
                        <Image src={campaignData.campaign_image} />
                        : <Image src={PlaceHolder} />

                    }
                    <div className='welcomeContent'>
                        <h2>Welcome to our {campaignData.campaing_name}</h2>

                        <div className=''>
                            <p>{campaignData.campaing_description}</p>


                        </div>
                    </div>
                    <div className='belowDivDisplay'>
                        <h4 className='text-danger text-start' style={{ marginTop: "4rem", marginLeft: "2rem", fontWeight: "800" }}>Register As</h4>
                        <div className='d-flex justify-content-between' style={{ marginInline: "1.3rem" }}>
                            <Link to='/register/individual' className='text-decoration-none'>
                                <Button onClick={() => handleToggleRegister('individual')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'individual' ? 'ActiveButton' : ''}`}>
                                    <i className='bi bi-person text-danger me-2'></i>
                                    Individual</Button>
                            </Link>

                            <Link to='/register/family'>
                                <Button onClick={() => handleToggleRegister('family')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'family' ? 'ActiveButton' : ''}`}>
                                    <i class="bi bi-people text-danger me-2"></i>
                                    Family</Button>
                            </Link>

                        </div>
                        <Link to='/register/corporate' className='text-decoration-none'>
                            <Button style={{ width: "90vw", marginTop: "1rem", marginLeft: "0rem" }} onClick={() => handleToggleRegister('corporate')} className={`btn btn-sm InactiveButton bg-transparent ${setUserRegister === 'corporate' ? 'ActiveButton' : ''}`}>
                                <i class="bi bi-building text-danger me-2 w-100"></i>
                                Corporate</Button>
                        </Link>
                        <div className='d-flex justify-content-center' style={{ position: "relative", padding: "0", marginInline: "0" }}>
                            <Link to='https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app'>
                                <Image src={GooglePlay} style={{ width: "auto", height: "5vh", marginTop: "0" }} />
                            </Link>

                            <Link to='https://apps.apple.com/ke/app/citam-church/id6480011240'>
                                <Image src={Appstore} style={{ width: "auto", height: "5vh" }} />

                            </Link>
                        </div>


                        <div className='d-block px-2 BottomDesign'>
                            <h2>Already have an account?</h2>
                            <Link to='/login' className='text-decoration-noe'>
                                <Button className='btn btn-sm w-100 bg-dark border-0 text-white'>LOGIN</Button>

                            </Link>
                            <Link className='text-decoration-none' to='https://jumuisha.com/'>
                                <span class="gradient-text">Powered by Jumuisha<span>&#174;</span></span>

                            </Link>

                        </div>

                    </div>


                </div>

            </div>


            <div className='desktopLanding'>
                <div className='empowermentSection'>
                    <div className='content'>
                        <h1 className='title'> {campaignData.campaing_name}
                            <Image src={Crown} style={{ width: "5%", marginLeft: "0.5rem" }} />
                        </h1>
                        <p className='description'>
                            {campaignData.campaing_description}
                            <br></br>
                            <Image src={Arrow} className='arrowlogin' />
                        </p>



                        <Link to='/register' className='donateButton'>
                            Register
                        </Link>
                        <Link to='/login' className='donateButton ms-5'>
                            Login

                        </Link>
                        <div className='d-flex donateButton bg-transparent' style={{ marginLeft: "2rem" }}>
                            <Link to='https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app'>
                                <Image src={GooglePlay} style={{ width: "auto", height: "5vh", margin: "0 auto" }} />
                            </Link>

                            <Link to='https://apps.apple.com/ke/app/citam-church/id6480011240'>
                                <Image src={Appstore} style={{ width: "auto", height: "5vh" }} />

                            </Link>
                        </div>

                        <div className='stats'>
                            <div className='statItem'>
                                <p className='statValue'>Ksh.{formatAmount(campaignData.campaign_amount)}+</p>
                                <p className='statLabel'>Campaign Target</p>
                            </div>
                            <div className='statItem'>
                                <p className='statValue'>{formatAmount(campaignData.users_support)}+</p>
                                <p className='statLabel'>Members support</p>
                            </div>
                            <div className='statItem'>
                                <p className='statValue'>{campaignData.remaining_months} Months</p>
                                <p className='statLabel'>Months remaining</p>
                            </div>
                        </div>
                    </div>
                    <div className='displayedCurvedDisplay'>
                        <svg viewBox="0 0 1440 288" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke="#00abbdFF" strokeWidth="15" strokeLinecap="round" d="M0,96L18.5,90.7C36.9,85,74,75,111,80C147.7,85,185,107,222,106.7C258.5,107,295,85,332,80C369.2,75,406,85,443,80C480,75,517,53,554,58.7C590.8,64,628,96,665,101.3C701.5,107,738,85,775,80C812.3,75,849,85,886,117.3C923.1,149,960,203,997,208C1033.8,213,1071,171,1108,133.3C1144.6,96,1182,64,1218,53.3C1255.4,43,1292,53,1329,96C1366.2,139,1403,213,1422,250.7L1440,288" />

                            {/* Top stroke */}
                            <path fill="none" stroke="#00abbdFF" strokeWidth="35" strokeLinecap="round" d="M0,96L18.5,90.7C36.9,85,74,75,111,80C147.7,85,185,107,222,106.7C258.5,107,295,85,332,80C369.2,75,406,85,443,80C480,75,517,53,554,58.7C590.8,64,628,96,665,101.3C701.5,107,738,85,775,80C812.3,75,849,85,886,117.3C923.1,149,960,203,997,208C1033.8,213,1071,171,1108,133.3C1144.6,96,1182,64,1218,53.3C1255.4,43,1292,53,1329,96C1366.2,139,1403,213,1422,250.7L1440,288" />
                            {/* Left, right, and bottom strokes */}
                            <path fill="none" stroke="white" strokeWidth="15" strokeLinecap="round" d="M0,96L18.5,90.7C36.9,85,74,75,111,80C147.7,85,185,107,222,106.7C258.5,107,295,85,332,80C369.2,75,406,85,443,80C480,75,517,53,554,58.7C590.8,64,628,96,665,101.3C701.5,107,738,85,775,80C812.3,75,849,85,886,117.3C923.1,149,960,203,997,208C1033.8,213,1071,171,1108,133.3C1144.6,96,1182,64,1218,53.3C1255.4,43,1292,53,1329,96C1366.2,139,1403,213,1422,250.7L1440,288" />
                            <path fill="white" fillOpacity="1" d="M0,96L18.5,90.7C36.9,85,74,75,111,80C147.7,85,185,107,222,106.7C258.5,107,295,85,332,80C369.2,75,406,85,443,80C480,75,517,53,554,58.7C590.8,64,628,96,665,101.3C701.5,107,738,85,775,80C812.3,75,849,85,886,117.3C923.1,149,960,203,997,208C1033.8,213,1071,171,1108,133.3C1144.6,96,1182,64,1218,53.3C1255.4,43,1292,53,1329,96C1366.2,139,1403,213,1422,250.7L1440,288L1440,0L1421.5,0C1403.1,0,1366,0,1329,0C1292.3,0,1255,0,1218,0C1181.5,0,1145,0,1108,0C1070.8,0,1034,0,997,0C960,0,923,0,886,0C849.2,0,812,0,775,0C738.5,0,702,0,665,0C627.7,0,591,0,554,0C516.9,0,480,0,443,0C406.2,0,369,0,332,0C295.4,0,258,0,222,0C184.6,0,148,0,111,0C73.8,0,37,0,18,0L0,0Z" />
                        </svg>

                    </div>

                    {/* <div className='imageContainer'>
            <img className='empowermentImage' src={Family} alt="" />
          </div> */}

                </div>
            </div >


        </>
    )
}

export default SharedCampaign
