import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify';
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { Button, Card, Container, Row, Col, Image, Modal, Form, FormSelect } from 'react-bootstrap'
import PaymentCalendar from './PaymentCalendar';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropUtils';
import PhoneNumberValidation from './PhoneNumberValidation';
import { Link } from 'react-router-dom';

function MakePledge({ showStatus }) {
  const [selectedDate, setSelectedDate] = useState(null);
  // make pledge data

  const [showPledge, setShowPledge] = useState(true)

  const showPledgeOpen = () => {
    setShowAccounts(false)
    setShowPledge(true)
  }
  const showPledgeClose = () => {
    localStorage.setItem('Pledge', false)
    setShowPledge(false)
    setSelectedDate(null)
  }

  const [showAccounts, setShowAccounts] = useState(false);

  const handleAccountClose = () => setShowAccounts(false);
  const handleAccountShow = () => setShowAccounts(true);

  const [showAddAccounts, setShowAddAccounts] = useState(false);

  const handleAddAccountClose = () => {
    setShowAddAccounts(false)
    setShowAccounts(true)
    setAccountType('other')
  }

  const handleAddAccountShow = () => {
    setShowAddAccounts(true)
    setShowAccounts(false)
  }
  const [pledgeType, setPledgeType] = useState('monetary')

  const handleChangePledgeType = (type) => {
    setPledgeType(type)
  }

  const [modeType, setModeType] = useState('one')

  const handleChangeModeType = (type) => {
    setModeType(type)
  }

  const [AccountType, setAccountType] = useState('mine')

  const handleChangeAccountType = (type) => {
    setAccountType(type)
  }


  const [AccountTypeAdd, setAccountTypeAdd] = useState(null)

  const handleChangeAccountTypeAdd = (type) => {
    setAccountTypeAdd(type)
  }
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  // get the data 
  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token to get the user id
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [userProfile, setUserProfile] = useState([])
  const [pledgeCount, setPledgeCount] = useState('')
  const [churchUser, setChurchUser] = useState({});
  const [campaign_data, setCampaignData] = useState({});
  const [monetaryCount, setMonetaryCount] = useState(0)
  useEffect(() => {
    // get the profiles for the current user
    serverUrl.get(`/user/profile/?user=${userId}`, config)
      .then((res) => {
        setUserProfile(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
    // get the data for the campaign
    serverUrl.get(`/pledge/giving-campaigns/`)
      .then((res) => {
        setCampaignData(res.data.results[0])

      })
      .catch((error) => {
        console.log(error)
      })

    serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
      .then((res) => {
        serverUrl.get(`/pledge/active-pledges?user_id=${userId}`, config)
          .then((res) => {
            setPledgeCount(res.data.results.length);
          })
          .catch((error) => {
            console.log(error);
          });
        setChurchUser(res.data.results[0]);


      })
      .catch((error) => {
        console.log(error);
      });


    // get the data for the pledges


  }, [userId])
  // add account functionality
  const [selectedUserProfileRelation, setSelectedUserProfileRelation] = useState('')
  const handleUserProfileRelationChange = (event) => {
    setSelectedUserProfileRelation(event.target.value);
  };

  const handleUserProfileClick = () => {
    const fileInput = document.getElementById('imageUpload');
    fileInput.click();
  };

  const [images, setImages] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const handleZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const handleChangeImage = (e) => {
    // const file = e.target.files[0];
    // setImages(file);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);


  };
  const handleCropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(images, croppedAreaPixels);
      console.log(croppedImage); // You can perform any action with the cropped image here
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  };

  const [userProfileName, setUserProfileName] = useState('')

  useEffect(() => {
    // get the monetary status
    if (AccountType === 'other') {
      serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}&profile_id=${selectedUserProfile}`, config)
        .then((res) => {
          setMonetaryCount(res.data.count)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}`, config)
        .then((res) => {
          setMonetaryCount(res.data.count)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [AccountType])
  function handleAddNewProfileAccount(e) {
    e.preventDefault();

    // Create a new FormData object
    const formData = new FormData();

    // Append form data to the FormData object
    formData.append('image', profileImageAccount);
    formData.append('user', userId);
    formData.append('relation', selectedUserProfileRelation);
    formData.append('profile_name', userProfileName);

    // upload the data
    serverUrl.post(`/user/profile/`, formData, config)
      .then((res) => {
        toast.success('Succesfully added the Account')
        setImages(null);
        setSelectedUserProfileRelation(null);
        setUserProfileName(null);
        setShowAddAccounts(false)
        handleChangeAccountType('other')
        setShowAccounts(true)

        // get the profiles for the current user
        serverUrl.get(`/user/profile/?user=${userId}`, config)
          .then((res) => {
            setUserProfile(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  let monetaryCountPledge;
  // make pledge 
  const [pledgeFormData, makePledgeFormData] = useState({
    pledgeAmount: "",
    currency: "KSH",
    payment_frequency: "",
    installment: "",
    estimated_amount: ""
  })
  const handlePledgeInput = (e) => {
    const { name, value } = e.target;

    makePledgeFormData({
      ...pledgeFormData,
      [name]: value,
    });
  };


  // get the currency data immediately 
  const [currencyRate, setCurrencyRate] = useState('')
  const valueKes = currencyRate * pledgeFormData.pledgeAmount

  useEffect(() => {
    if (pledgeFormData.currency !== 'KSH') {
      serverUrl.get(`/pledge/get-currency?amount=1&currency=${pledgeFormData.currency}`, config)
        .then((res) => {
          setCurrencyRate(res.data.converted_amount)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [pledgeFormData.currency])
  const [smsChecked, setSmsChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);

  const handleSmsChange = (e) => {
    setSmsChecked(e.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmailChecked(e.target.checked);
  };
  let idNumber;
  let UserType;
  const [userUniqueIdentifier, setUserUniqueIdentifier] = useState('')


  function handleMakePlede(e) {
    e.preventDefault()


    var dollar_amount_new;
    var churchData;
    var endDate;
    let pledgeAmount;


    endDate = localStorage.getItem('endDate')

    if (pledgeFormData.estimated_amount) {
      dollar_amount_new = parseInt(pledgeFormData.estimated_amount);
    }
    else if (pledgeFormData.pledgeAmount) {
      dollar_amount_new = parseInt(pledgeFormData.pledgeAmount);
    }

    if (pledgeFormData.currency !== 'KSH') {
      pledgeAmount = valueKSH
    }
    else {
      pledgeAmount = dollar_amount_new
    }
    if (churchUser) {
      churchData = churchUser.church
      console.log(churchUser)
    } else {
      null
    }
    let selectedUserProfile;
    const PledgeData = {
      amount: pledgeAmount,
      original_amount: pledgeAmount,
      currency: pledgeFormData.currency,
      pledge_type: pledgeType,
      in_kind_name: pledgeFormData.in_kind_name,
      payment_frequency: pledgeFormData.payment_frequency,
      installment_start_date: selectedDate,
      installment: pledgeFormData.installment,
      status: "unfulfilled",
      user: userId,
      giving_campaign: campaign_data.id,
      church: churchData,
      profile: selectedUserProfile,
      estimated_amount: pledgeFormData.estimated_amount,
      is_sms: smsChecked,
      is_email: emailChecked,

    }
    if (pledgeFormData.currency !== 'KSH') {
      PledgeData.dollar_amount = pledgeFormData.pledgeAmount

    }
    if (formattedEndDateData && formattedEndDateData !== "01/01/1970") {
      const originalDateStr = formattedEndDateData;
      const parts = originalDateStr.split('/');
      const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
      PledgeData.installment_end_date = formattedDate

    }
    // create the pledge 
    serverUrl.post('/pledge/', PledgeData, config)
      .then((res) => {
        toast.success('Succesfully Added the pledge')
        makePledgeFormData({
          pledgeAmount: "",
          currency: "KSH",
          payment_frequency: "",
          installment: "",
          estimated_amount: "",
        });
        setAccountType('mine')
        setSelectedUserProfile('')
        setShowPledge(false)
        setPledgeType('monetary')
        setSelectedDate(null)
        setConfrimSavePledge(false)
        // get the pledge
        serverUrl.get(`/pledge/active-pledges?user_id=${userId}`, config)
          .then((res) => {
            setPledgeCount(res.data.results.length)
          })
          .catch((error) => {
            console.log(error)
          })
        // post the id number to the backend

        if (!idNumber) {
          const dataUser = {
            id_number: userUniqueIdentifier
          }
          serverUrl.patch(`/user/edit-user/${userId}`, dataUser, config)
            .then((res) => {
              serverUrl
                .get(`/user/user-details/?id=${userId}`, config)
                .then((res) => {
                  // get the full name of the user
                  setFullName(res.data.results[0].full_name);
                  setUseeImage(res.data.results[0].profile_picture);
                  setIdNumber(res.data.results[0].id_number)
                  setUserType(res.data.results[0].account_type)
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error)
            })

        }

      })
      .catch((error) => {
        console.log(error)
        toast.error('An error occured')

      })
  }

  const [showModalSchedule, setShowModalSchedule] = useState(false)

  const showScheduleOpen = () => {


    const campaignEndDate = new Date(ends_on);

    if (endDate > campaignEndDate) {
      setAddErrorMessage(true)
      return;
    }
    else {
      setShowModalSchedule(true)
      setShowPledge(false)
    }
  }
  const showScheduleClose = () => {
    setShowPledge(true)
    setShowModalSchedule(false)
  }

  const [confirmSavePledge, setConfrimSavePledge] = useState(false)

  let numberOfInstallments;

  function calculateEndDate() {
    // Convert startDate to Date object
    const startDateObj = new Date(selectedDate);

    // Calculate the number of installments required
    numberOfInstallments = Math.ceil(pledgeFormData.pledgeAmount / pledgeFormData.installment);
    const endDate = new Date(startDateObj);

    // Adjust frequency for months and years
    if (pledgeFormData.payment_frequency === 'monthly') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === '3months') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 3, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === '6months') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 6, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === 'yearly') {
      endDate.setFullYear(endDate.getFullYear() + numberOfInstallments, endDate.getMonth(), endDate.getDate());
    } else if (pledgeFormData.payment_frequency === 'weekly') {
      endDate.setDate(endDate.getDate() + numberOfInstallments * 7); // Adjust based on number of weeks in a year
    } else if (pledgeFormData.payment_frequency === '2weeks') {
      endDate.setDate(endDate.getDate() + numberOfInstallments * 14); // Adjust based on number of bi-weekly periods in a year
    } else {
      // Handle other frequencies
      numberOfInstallments *= 1; // Adjust accordingly
    }



    return endDate;
  }
  const endDate = calculateEndDate();

  const [addErrorMessage, setAddErrorMessage] = useState(false)
  // Assuming endDate is a Date object
  const formattedEndDate = `${endDate.toLocaleString('default', { month: 'long' })} ${endDate.getDate()} ${endDate.getFullYear()}`;
  const formattedEndDateData = new Date(endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  let ends_on;
  let formattedStartDate = '';

  if (selectedDate !== null && selectedDate !== undefined) {
    const dateObj = new Date(selectedDate);
    formattedStartDate = `${dateObj.toLocaleString('default', { month: 'long' })} ${dateObj.getDate()} ${dateObj.getFullYear()}`;
  }
  let ends_on_date;

  ends_on_date = new Date(ends_on);


  const formattedEndsOn = `${ends_on_date.toLocaleString('default', { month: 'long' })} ${ends_on_date.getDate()} ${ends_on_date.getFullYear()}`;




  const showSavePledge = () => {

    // Calculate the end date
    if (pledgeType === 'monetary' && !pledgeFormData.pledgeAmount) {
      toast.error('Kindly enter the amount for the pledge')
      return
    }
    if (modeType === 'regular' && !pledgeFormData.payment_frequency) {
      toast.error('Kindly ensure the pledge frequency is added')
      return
    }
    if (modeType === 'regular' && pledgeFormData.payment_frequency && !selectedDate) {
      toast.error('Kindly ensure the start installment date for the pledge is set')
      return
    }
    if (modeType === 'regular' && !pledgeFormData.installment && pledgeFormData.payment_frequency) {
      toast.error('Kindly ensure the installment amount for the pledge is added.');
      return;
    }


    const campaignEndDate = new Date(ends_on);

    // Check if the end date exceeds the campaign end date
    if (endDate > campaignEndDate) {
      // toast.error('The end date exceeds the campaign end date. Pledge cannot be created.');
      setAddErrorMessage(true)
      return;
    }
    else

      if (!pledgeFormData.in_kind_name && !pledgeFormData.estimated_amount && pledgeType === 'in-kind') {
        toast.error('Please fill any of the fields.');
        return;
      }
      else {
        setConfrimSavePledge(true)
        setShowPledge(false)
      }
  }
  const closeSavePledge = () => {
    setConfrimSavePledge(false)

  }
  const closeSavePledgeReturn = () => {
    setConfrimSavePledge(false)
    setShowPledge(true)

  }
  let cropImage;
  return (
    <div>



      {/* moodals data */}

      {/* modal to show accounts */}
      <Modal show={showAddAccounts} onHide={handleAddAccountClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Add Account</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }}>
          <Form className='makePledgeForm' onSubmit={handleAddNewProfileAccount}>
            <input
              type='text'
              placeholder='Enter Name'
              className='input-field'
              onChange={(e) => setUserProfileName(e.target.value)}
              style={{ fontSize: "14px" }}
            />
            <FormSelect onChange={handleUserProfileRelationChange} style={{ fontSize: "14px" }}
            >
              <option selected disabled value=''>Select relation</option>
              <option value='child'>Child</option>
              <option value='spouse'>Spouse</option>
              <option value='other'>Other</option>
            </FormSelect>

            <Button style={{ fontSize: '14px' }} className="btn btn-sm bg-transparent text-danger border-danger photoOptional" onClick={() => document.getElementById('imageUpload').click()}>Select Photo (Optional)</Button>
            <br />
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            {images && images instanceof File && (
              <div>
                <Cropper
                  image={URL.createObjectURL(images)}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}
            {cropImage instanceof Blob && (
              <div>
                <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
              </div>
            )}

          </Form>

        </Modal.Body>
        <Modal.Footer>
          {images && images instanceof File ? (

            <p onClick={handleCropImage}>Crop Image</p>
          ) : (
            <>
              <p className='text-dark' onClick={handleAddAccountClose}>Close</p>
              <p className='text-danger' onClick={handleAddNewProfileAccount}>Save</p>
            </>
          )}


        </Modal.Footer>

      </Modal>

      {/* modal to add account */}

      <Modal show={showAccounts} onHide={handleAccountClose} centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <Form className='makePledgeForm'>
            {userProfile.length > 0 ?
              <p style={{ fontSize: "14px" }}>Select Account</p>
              :
              <><p style={{ fontSize: "14px" }}>Kindly Add An Account</p>
              </>
            }
            <span style={{ fontSize: "12px", marginBottom: "0.8rem" }}>Please select the account you want to pledge for</span>
            <div className='d-flex mt-2 mb-0'>
              <Button style={{ fontSize: "13px" }} className={`btn btn-sm  text-white  me-4 ${AccountType === 'mine' ? 'btn-danger border-0 text-white' : 'bg-transparent text-danger  border-danger'} `}
                onClick={() => handleChangeAccountType('mine')}>My Account</Button>

              <Button style={{ fontSize: "13px" }} className={`btn btn-sm  ${AccountType === 'other' ? 'btn-danger border-0 text-white' : 'bg-transparent text-danger border-danger'} `}
                onClick={() => handleChangeAccountType('other')}>Other Account</Button>
            </div>



            {AccountType === 'other' ?

              <>
                {userProfile.length > 0 ?
                  <FormSelect style={{ fontSize: "13px" }} onChange={handleUserProfileChange}>
                    <option selected disabled value=''>Select Account</option>

                    {userProfile.map((account) => (
                      !specialProfiles.includes(account.id) && (
                        <option key={account.id} value={account.id}>
                          {account.profile_name}
                        </option>
                      ))
                    )}


                  </FormSelect>
                  :
                  <option selected disabled></option>
                }

                <div className='d-flex justify-content-between'>
                  <span
                    style={{
                      fontSize: "12px",
                      paddingTop: "0.5rem",
                      cursor: "pointer"
                    }}
                    className='btn btn-sm btn-dark text-white'
                    onClick={handleAddAccountShow}
                  >
                    Add New Account
                  </span>


                </div>
              </>

              :
              <>
              </>
            }


          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm btn-secondary text-white' onClick={handleAccountClose}>Close</Button>
          <Button className='btn btn-sm btn-danger border-0 text-white' onClick={showPledgeOpen}>Make Pledge</Button>
        </Modal.Footer>

      </Modal>

      {/* modals to show the data */}
      <Modal show={showPledge} onHide={showPledgeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger'>Make pledge </Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "70vh", overflowY: "scroll", overflowX: "hidden" }}>
          <Form className='makePledgeForm' onSubmit={handleMakePlede}>
            <div className='d-flex'>

              <Button className={`btn btn-sm  me-4 ${pledgeType === 'monetary' ? 'btn-danger border-0 text-white' : 'bg-transparent text-danger  border-danger'} `}
                onClick={() => handleChangePledgeType('monetary')}>Monetary</Button>
              {AccountType === 'other' ?

                <></>
                :

                <Button className={`btn btn-sm  ${pledgeType === 'in-kind' ? 'btn-danger border-0 text-white' : 'bg-transparent text-danger border-danger'} `}
                  onClick={() => handleChangePledgeType('in-kind')}>In-Kind</Button>
              }
            </div>


            {pledgeType === 'monetary' ?
              monetaryCount !== 0 ?
                <>
                  <span>You can only have one monetary pledge for this account</span>
                  <br></br>
                  {/* <Link className='mt-3' to={`/pledge/${monetaryCountPledge.payment_code}`}>
                    <Button className='btn btn-sm btn-dark text-white'>View Pledge</Button>

                  </Link> */}

                </>
                :
                <>


                  <p>Enter pledge amount <b className='text-danger'>*</b></p>

                  <div>
                    <select value={pledgeFormData.currency}
                      onChange={handlePledgeInput}
                      className='kshInput'
                      style={{ width: "17%", height: "5vh" }}
                      name='currency'>
                      <option value='KSH'>KSH</option>
                      <option value='USD'>USD</option>
                      <option value='EUR'>EUR</option>
                      <option value='GBP'>GBP</option>
                    </select>

                    <input
                      type='number'
                      className='amountKshInput'
                      value={pledgeFormData.pledgeAmount}
                      onChange={handlePledgeInput}
                      name='pledgeAmount'
                      style={{ width: "80%" }}
                      required
                    />
                  </div>
                  {pledgeFormData.currency !== 'KSH' && pledgeFormData.pledgeAmount &&
                    <p className='text-danger' style={{ fontSize: "10px", textAlign: "right" }}>KSH {Math.round(valueKSH).toLocaleString('en-US')}</p>
                  }
                  <p>Select the mode of redemption</p>
                  <div className='d-flex'>
                    <Button className={`btn btn-sm  me-4 ${modeType === 'one' ? 'btn-danger border-0 text-white' : 'bg-transparent text-danger border-danger'} `}
                      onClick={() => handleChangeModeType('one')}>One-Off</Button>

                    <Button className={`btn btn-sm  ${modeType === 'regular' ? 'btn-danger border-0  text-white' : 'bg-transparent text-danger border-danger'} `}
                      onClick={() => handleChangeModeType('regular')}>Regular</Button>
                  </div>
                </>

              :
              <>
                {idNumber ?
                  <></>
                  :
                  <>
                    <p>Kindly enter your {UserType !== 'corporate' ? <>identification Number</> : <>Kra pin</>} <b className='text-danger'>*</b></p>

                    <div>
                      <input
                        type='text'
                        className='amountKshInput'
                        value={userUniqueIdentifier}
                        onChange={(event) => setUserUniqueIdentifier(event.target.value)}
                        name='userUniqueIdentifier'
                        style={{ width: "100%" }}
                        required
                      />
                    </div>
                  </>
                }


                <p>Describe the In-Kind contribution</p>
                <textarea
                  placeholder='Enter description'
                  row={4}
                  value={pledgeFormData.in_kind_name}
                  onChange={handlePledgeInput}
                  name='in_kind_name'
                >

                </textarea>
                <p>Enter estimated value of In-Kind contribution<b className='text-danger'>*</b></p>
                <div>
                  <select value={pledgeFormData.currency}
                    onChange={handlePledgeInput}
                    className='kshInput'
                    style={{ width: "17%", height: "5vh" }}
                    name='currency'
                    required
                  >
                    <option value='KSH'>KSH</option>
                    <option value='USD'>USD</option>
                    <option value='EUR'>EUR</option>
                    <option value='GBP'>GBP</option>

                  </select>

                  <input
                    type='number'
                    className='amountKshInput'
                    value={pledgeFormData.estimated_amount}
                    onChange={handlePledgeInput}
                    name='estimated_amount'
                    style={{ width: "80%" }}
                  />
                </div>
              </>
            }

            {pledgeType === 'monetary' ?
              modeType === 'regular' ?
                <>
                  <p>Select redemption frequency  <b className='text-danger'>*</b></p>
                  <FormSelect name='payment_frequency' onChange={handlePledgeInput} value={pledgeFormData.payment_frequency}>
                    <option value='' selected disabled>Select Frequency</option>
                    <option value='weekly'>Every Week</option>
                    <option value='2weeks'>Every 2 Weeks</option>
                    <option value='monthly'>Every Month</option>
                    <option value='3months'>Every 3 Months</option>
                    <option value='6months'>Every 6 Months</option>
                    <option value='yearly' >Every Year</option>
                  </FormSelect>

                  {pledgeFormData.payment_frequency && (
                    <>
                      <p>Enter redemption start date  <b className='text-danger'>*</b></p>

                      <div className='border-danger input-container' style={{ width: "100%", borderRadius: "6px" }}>
                        <Image
                          src={Calendar}
                          alt="Calendar"
                          style={{ width: '5%', marginRight: '0.5rem', marginLeft: '0.3rem', cursor: 'pointer' }}
                        >
                        </Image>


                        {selectedDate ? (
                          <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1'>{selectedDate}</span>
                        ) : (
                          <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1 p-2' >Kindly select a date</span>
                        )}
                        <input
                          type='date'
                          onChange={handleDateChange}
                        />


                      </div>

                      {pledgeFormData.pledgeAmount !== '' && (
                        <>
                          <p>Enter the amount of each installment <b className='text-danger'>*</b></p>
                          <input
                            type='number'
                            className='inputNumberInstallement'
                            onChange={handlePledgeInput}
                            name='installment'
                            value={pledgeFormData.installment}
                            disabled={!isInstallmentEditable}
                          />
                        </>
                      )}
                    </>
                  )}

                </>
                :
                <></>
              :
              <></>
            }

            {pledgeType === 'monetary' ?
              monetaryCount !== 0 ?
                <></>
                :
                <>
                  <p className='mt-2'>Opt in to pledge reminders</p>
                  <div>
                    <>
                      <input
                        type='checkbox'
                        checked={smsChecked}
                        onChange={handleSmsChange}
                      />
                      <span className='checked'>via SMS</span>
                    </>
                    <>
                      <input
                        type='checkbox'
                        checked={emailChecked}
                        onChange={handleEmailChange}
                      />
                      <span className='checked'>via Email</span>
                    </>
                  </div>
                </>
              :
              <>

              </>
            }

          </Form>


        </Modal.Body>
        <Modal.Footer>

          {pledgeType === 'monetary' ?
            monetaryCount !== 0 ?
              <></>
              :
              <Button className='btn btn-sm btn-danger border-0 text-white' onClick={showSavePledge}>Make Pledge</Button>
            :
            <Button className='btn btn-sm btn-danger border-0  text-white' onClick={showSavePledge}>Make Pledge</Button>
          }

          {
            pledgeFormData.installment === "" ?
              <>
              </>
              :
              pledgeType === 'monetary' && selectedDate && pledgeFormData.installment &&
              <Button className='btn btn-sm btn-dark text-white' onClick={showScheduleOpen}>View Schedule</Button>

          }
        </Modal.Footer>

      </Modal>

      {/* confirmation modal */}
      <Modal show={confirmSavePledge} onHide={closeSavePledge}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Confirm Pledge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pledgeType === 'monetary' ?
            pledgeFormData.pledgeAmount > 0 ?
              <p>
                Proceed to make a pledge
                {pledgeFormData.pledgeAmount !== null && pledgeFormData.pledgeAmount !== undefined ?
                  <span>
                    {" "} of <span className='text-danger'>{pledgeFormData.currency}. {parseInt(pledgeFormData.pledgeAmount).toLocaleString('en-US')} </span>
                    {pledgeFormData.installment !== null && pledgeFormData.installment !== undefined ?
                      <span>
                        {numberOfInstallments !== 0 && numberOfInstallments !== null && numberOfInstallments !== undefined && numberOfInstallments !== Infinity ?
                          <>redeemed in <span className='text-danger'>{numberOfInstallments}</span> installments </>
                          :
                          <></>
                        }
                        {pledgeFormData.installment ?
                          <>
                            of < span className='text-danger'>{pledgeFormData.currency}. {pledgeFormData.installment && (parseInt(pledgeFormData.installment).toLocaleString('en-US'))} </span>
                            each <span className='text-danger' style={{ textTransform: "capitalize" }}>
                              {pledgeFormData.payment_frequency !== null && pledgeFormData.payment_frequency !== undefined
                                ? (pledgeFormData.payment_frequency === 'weekly' ? 'week' :
                                  pledgeFormData.payment_frequency === 'monthly' ? 'month' :
                                    pledgeFormData.payment_frequency === 'yearly' ? 'year' : pledgeFormData.payment_frequency)
                                : ''}
                            </span>
                          </>

                          :
                          <></>
                        }
                        {selectedDate !== null && selectedDate !== undefined && formattedEndDate !== null && formattedEndDate !== undefined ?
                          <span> starting on <span className='text-danger'>{formattedStartDate}</span> and ending on <span className='text-danger'>{formattedEndDate}</span></span>
                          :
                          <span></span>
                        }
                      </span>
                      :
                      <span></span>
                    }
                  </span>
                  :
                  <span></span>
                }
              </p>


              :
              <p>Proceed to make your {pledgeType} pledge</p>
            : pledgeFormData.estimated_amount > 0 ?
              <p>Proceed to make your {pledgeType}  pledge with an estimation of <span className='text-danger'>{pledgeFormData.currency}. {parseInt(pledgeFormData.estimated_amount).toLocaleString('en-US')} </span></p>
              :
              <p>Proceed to make your {pledgeType} pledge</p>
          }

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeSavePledgeReturn}>
            Cancel
          </Button>
          <Button variant="danger" className='border-0' onClick={handleMakePlede}>
            Confirm
          </Button>
        </Modal.Footer>

      </Modal>

      {/* modal for viewing the schedule */}
      <Modal show={showModalSchedule} onHide={showScheduleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Redemption Schedule</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex justify-content-between'>
            <Card className='d-flex topDisplayCard endDate'>
              <Card.Body>
                <i className='bi bi-calendar-week me-2'></i>
                <div>
                  <p>Pledge End Date</p>
                  <span>

                    <PaymentCalendar

                      installmentAmount={pledgeFormData.installment}
                      paymentFrequency={pledgeFormData.payment_frequency}
                      totalAmount={pledgeFormData.pledgeAmount}
                      startDate={selectedDate}
                      displayType='end_date'
                    />

                  </span>
                </div>
              </Card.Body>

            </Card>
            <Card className='d-flex topDisplayCard nextDate'>
              <Card.Body>
                <i className='bi bi-calendar2-check me-2'></i>
                <div>
                  <p>Next Redemption Date</p>
                  <span>
                    <PaymentCalendar

                      installmentAmount={pledgeFormData.installment}
                      paymentFrequency={pledgeFormData.payment_frequency}
                      totalAmount={pledgeFormData.pledgeAmount}
                      startDate={selectedDate}
                      displayType='next_payment_date'
                    />
                  </span>
                </div>
              </Card.Body>

            </Card>

            <Card className='d-flex topDisplayCard frequencyDate'>
              <Card.Body>
                <i className='bi bi-calendar-plus me-2'></i>
                <div>
                  <p>Redemption frequency</p>
                  <span>
                    {pledgeFormData.payment_frequency}
                  </span>
                </div>
              </Card.Body>

            </Card>

          </div>


          <Card className='TopDisplayMetrics'>
            <Card.Body>
              <div>
                <span>Total</span>
                {pledgeFormData.pledgeAmount && (
                  <p>Ksh.{pledgeFormData.pledgeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}


              </div>

              <div>
                <span>Installments</span>
                {pledgeFormData.installment && (
                  <p>Ksh.{pledgeFormData.installment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}


              </div>

              <div>
                <span>Balance</span>
                {pledgeFormData.pledgeAmount && (
                  <p>Ksh.{pledgeFormData.pledgeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}
              </div>
            </Card.Body>

          </Card>

          <PaymentCalendar

            installmentAmount={pledgeFormData.installment}
            paymentFrequency={pledgeFormData.payment_frequency}
            totalAmount={pledgeFormData.pledgeAmount}
            startDate={selectedDate}
            displayType='calendar'
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={showScheduleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      {/* showing error message */}
      <Modal show={addErrorMessage} onHide={() => setAddErrorMessage(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Change frequency or installment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The pledge end date {formattedEndDate} exceeds the campaign expiry {formattedEndsOn}. Please adjust the installment of frequency</p>

        </Modal.Body>




      </Modal>

    </div>
  )
}

export default MakePledge