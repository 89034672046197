import axios from "axios";

let baseURL;
let webSocket;
let envUrl;
// baseURL = "http://127.0.0.1:8001/api";
// envUrl = "http://127.0.0.1:8001";
// webSocket = "ws://127.0.0.1:8001/ws";

webSocket = "ws://capstonefaithministry.net/ws"
baseURL = "https://capstonefaithministry.net/api";
envUrl = "https://capstonefaithministry.net/";



export const serverUrl = axios.create({
  baseURL: baseURL,
});

export const webSocketUrl = webSocket;
export const currentUrl = envUrl;