import React, { useEffect, useState } from 'react';
import { Accordion, Card, Tab, Tabs, Button, Image, Modal, Form, FloatingLabel, InputGroup, Row, Col } from 'react-bootstrap';
import '../../assets/scss/HelpDesk.scss';
import GoogleDocs from '../../assets/img/google-docs.png';
import Youtube from '../../assets/img/youtube.png';
import Share from '../../assets/img/share_faq.png';
import TicketView from './TickectView';
import { Link } from 'react-router-dom';
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { FormControl } from 'react-bootstrap/esm';
import { toast } from 'react-toastify'
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'

function HelpDesk() {
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [activeTab, setActiveTab] = useState("report");
    const accessToken = localStorage.getItem('authTokens');
    if (accessToken) {
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` },
        };
        // decoding the token to get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    }


    const handleTicketClick = (ticket) => {
        setSelectedTicket(ticket);
        serverUrl.get(`/user/mark-thread-as-read?help_issue_id=${ticket.id}`)
            .then((res) => {

            })
            .catch((error) => {
                console.log(error)
            })

    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSelectedTicket(null);
    };


    // Dummy data for ticket details
    const [ticketDetails, setTicketDetails] = useState([])
    const [faqItems, setFaqItems] = useState([])
    const [faqAnswer, setAnswer] = useState([])
    const foundUser = localStorage.getItem('user')
    const foundHelpRequester = localStorage.getItem('help_requester')


    useEffect(() => {
        const fetchData = async () => {
            if (accessToken) {
                // If access token exists
                const parsedTokens = JSON.parse(accessToken);
                const access = parsedTokens.access;
                const config = {
                    headers: { Authorization: `Bearer ${access}` },
                };
                const decodedToken = jwtDecode(accessToken);
                const userId = decodedToken.user_id;
                const unreadCounts = {};

                try {
                    const res = await serverUrl.get(`/user/get-user-help-issues/?user=${userId}`, config);
                    const results = res.data.results;

                    const updatedDetails = await Promise.all(results.map(async (ticket) => {
                        try {
                            const unreadRes = await serverUrl.get(`/user/get-unread-conversation/?help_issue_id=${ticket.id}`);
                            const unreadCount = unreadRes.data.unread_count;

                            return { ...ticket, unread_count: unreadCount };
                        } catch (error) {
                            console.error(`Error fetching unread count for ticket ${ticket.id}:`, error);
                            return { ...ticket, unread_count: 0 };
                        }
                    }));

                    setTicketDetails(updatedDetails);


                }
                catch (error) {
                    console.error('Error fetching user help issues:', error);
                }
            } else {
                let apiUrl;
                if (foundUser) {
                    apiUrl = `/user/get-user-help-issues/?user=${foundUser}`;
                } else if (foundHelpRequester) {
                    apiUrl = `/user/help-issue/?support_requester=${foundHelpRequester}`;
                }

                try {
                    const res = await serverUrl.get(apiUrl);
                    const updatedDetails = await Promise.all(results.map(async (ticket) => {
                        try {
                            const unreadRes = await serverUrl.get(`/user/get-unread-conversation/?help_issue_id=${ticket.id}`);
                            const unreadCount = unreadRes.data.unread_count;

                            return { ...ticket, unread_count: unreadCount };
                        } catch (error) {
                            console.error(`Error fetching unread count for ticket ${ticket.id}:`, error);
                            return { ...ticket, unread_count: 0 };
                        }
                    }));

                    setTicketDetails(updatedDetails);
                } catch (error) {
                    console.error('Error fetching user help issues:', error);
                }
            }

            try {
                const faqAnswerRes = await serverUrl.get(`/user/faq-question-answer/`);
                setAnswer(faqAnswerRes.data.results);
            } catch (error) {
                console.error('Error fetching FAQ answers:', error);
            }

            try {
                const faqQuestionRes = await serverUrl.get(`/user/faq-question/`);
                setFaqItems(faqQuestionRes.data);
            } catch (error) {
                console.error('Error fetching FAQ questions:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 3000);
        return () => clearInterval(intervalId);

    }, []);



    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', options);
        const [month, day, year, time] = formattedDate.split(' ');
        const cleanedDay = day.replace(/,/g, ''); // Remove any commas in day
        const suffix = getDaySuffix(parseInt(cleanedDay, 10)).replace(/,/g, ''); // Remove any commas in suffix
        const meridiem = date.getHours() < 12 ? 'AM' : 'PM';
        return `${cleanedDay}${suffix} ${month}, ${year} ${time} ${meridiem}`;
    };


    const getDaySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };
    const [showCallModal, setShowCallModal] = useState(false)
    const handleAdd = () => {
        setShowCallModal(true)
    }
    const handleClose = () => {
        setShowCallModal(false)
    }
    const [phoneNumber, setPhoneNumber] = useState('')
    function handleSavePhone(e) {
        e.preventDefault()
        const data = {
            phone_number: phoneNumber,
            call_status: 'pending'
        }
        serverUrl.post(`/user/helpdesk-call-phone-number/`, data)
            .then((res) => {
                setShowCallModal(false)
                setPhoneNumber(null)
                toast.success('Thank you for your response. Kindly wait as our team gets back to you')
            })
            .catch((error) => {
                console.log(error)
            })
    }


    
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredFAQItems = faqItems.filter((faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const goBack = () => {
        if (selectedTicket) {
            setActiveTab('report');
            setSelectedTicket(null);
        }
        else {
            window.history.back();

        }
    };

    // sharing youtube
    const [show, setShow] = useState(false);
    const [faqShare, setFaqShare] = useState({});

    const handleCloseModal = () => setShow(false);
    const handleShow = (faq) => {
        setShow(true)
        setFaqShare(faq)
    }
    const [copied, setCopied] = useState(false);

    const description = `To view more about this FAQ on ${faqShare.question}`
    let followedDescription

    if (faqShare.pdf_file) {
        followedDescription = `Watch Youtube\n ${faqShare.you_tube_link}\nView PDF\n ${faqShare.pdf_file}`
    }
    else {
        followedDescription = `Watch Youtube\n ${faqShare.you_tube_link}`

    }
    const descriptionNew = `${description}\n${followedDescription}`

    const url = faqShare.you_tube_link;

    const handleCopyLink = () => {
        const textToCopy = `${url}`;
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setCopied(true);
        toast.success("Linked is copied");
    };



    const messageLink = `${descriptionNew}`;

    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        messageLink
    )}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        messageLink
    )}`;

    const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        messageLink
    )}`;

    const instagramLink = "https://www.instagram.com/direct/inbox/";

    function shareFacebook() {
        let url = encodeURIComponent(messageLink);
        let title = "title";
        window.open(
            `http://www.facebook.com/sharer.php?u=${url}`,
            "pop up name",
            "width=600, height=600"
        );
    }

    const handlePlatform = (platform) => {
        var Platform = platform;

        if (platform === "whatsapp") {
            window.open(whatsappLink, "_blank");
        } else if (platform === "facebook") {
            // Handle Facebook logic here
            // ...
        } else if (platform === "gmail") {
            window.open(EmailLink);
        } else if (platform === "x") {
            window.open(twitterLink, "_blank");
        } else if (platform === "instagram") {
            window.open(instagramLink, "_blank");
        } else if (platform === "copy_link") {
            handleCopyLink();
        }

    };
    return (
        <>
            <div className='helpDesk'>
                <div className='d-flex '>
                    <p onClick={goBack} className='d-flex'>
                        <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem", marginTop: "0.5rem" }}></i>
                    </p>
                    <h2 className='text-dark text-center' style={{ alignContent: "center", margin: "0 auto", fontSize: "25px", fontWeight: "500" }}>Help Desk</h2>
                </div>
                {!selectedTicket && (
                    <Tabs defaultActiveKey="report" id="helpDeskTabs" activeKey={activeTab} onSelect={handleTabChange}>
                        <Tab eventKey="report" title="Report">
                            <div className="">
                                {ticketDetails.length === 0 ?
                                    <>
                                        <p className='text-danger text-center'>No report issues at the moment</p>
                                    </>
                                    :
                                    !selectedTicket && ticketDetails.map((ticket, index) => (
                                        <Card key={index} onClick={() => handleTicketClick(ticket)}>
                                            <Card.Body >
                                                <div className='d-flex justify-content-between'>
                                                    <Card.Title className='text-danger fw-bold' style={{ fontSize: "16px", fontWeight: "bolder" }}>Ticket  {ticket.ticket_number}</Card.Title>
                                                    <div className='d-flex'>
                                                        {ticket.unread_count !== 0 && ticket.unread_count && (
                                                            <span className='counterSpan' style={{ fontWeight: "700", backgroundColor: ticket.issues_status === 'ongoing' ? '#E74C3C' : ticket.issues_status === 'pending' ? '#F39C12' : '#2ECC71' }}>
                                                                {ticket.unread_count}
                                                            </span>
                                                        )}

                                                        <Card.Title style={{ fontSize: "16px", fontWeight: "700", textTransform: "capitalize", color: ticket.issues_status === 'ongoing' ? '#E74C3C' : ticket.issues_status === 'pending' ? '#F39C12' : '#2ECC71' }}>{ticket.issues_status}</Card.Title>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <Card.Text className={`text-white ticketType ${ticket.issue_category === 'suggestion' ? 'bg-success' : 'bg-primary'}`}>{ticket.issue_category}</Card.Text>
                                                    <Card.Text>{formatDate(ticket.created_at)}</Card.Text>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                                {!selectedTicket &&
                                    <Link to={'/report-issue'} className='text-decoration-none '>
                                        <Button className=' btn btn-danger border-0 text-white fw-bolder BottomButtons'><Image src={GoogleDocs} className='me-2' />Report Issue</Button>

                                    </Link>

                                }
                            </div>
                        </Tab>
                        <Tab eventKey="faq" title="FAQs">
                            <Form.Control
                                type="text"
                                name="name"
                                className='faqsearchButton'
                                placeholder='Search FAQ'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />



                            <Accordion className="mt-2" defaultActiveKey="0" >
                                {filteredFAQItems.map((faq, index) => {
                                    // Filter the answers based on the FAQ question ID
                                    const filteredAnswers = faqAnswer.filter(answer => answer.question === faq.id);

                                    return (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header style={{ fontWeight: 'bold' }}>{faq.question}</Accordion.Header>
                                            <Accordion.Body>
                                                <div>

                                                    <ol style={{ listStyle: "none" }}>
                                                        {filteredAnswers
                                                            .sort((a, b) => {
                                                                // Extract the numerical part from the beginning of the answers
                                                                const numA = parseInt(a.faq_answer.split('.')[0]);
                                                                const numB = parseInt(b.faq_answer.split('.')[0]);

                                                                return numA - numB; // Sort based on the numerical part
                                                            })
                                                            .map((answer, idx) => (
                                                                <li key={idx} className='list-style-none'>
                                                                    {answer.faq_answer}<br></br>
                                                                    {answer.faq_image && <img src={answer.faq_image} style={{ width: "40%" }} alt="FAQ Image" />}
                                                                </li>
                                                            ))}
                                                    </ol>


                                                    {faq.you_tube_link ?

                                                        <div className='d-flex' style={{ width: "auto", justifyContent: "right" }}>
                                                            <Link to={faq.you_tube_link} className='justify-content-end w-25 text-decoration-none d-flex'>
                                                                <Button className='bg-transparent text-dark border-dark' style={{ width: "100px" }}>
                                                                    <span>Watch</span>
                                                                    <img src={Youtube} alt="YouTube" className="youtube-image-side" />

                                                                </Button>


                                                            </Link>
                                                            <Link className='justify-content-end w-25 text-decoration-none d-flex'>
                                                                <Button className='bg-transparent text-dark border-dark' style={{ width: "100px" }} onClick={(e) => { handleShow(faq) }}>
                                                                    <span>Share</span>
                                                                    <img src={Share} alt="YouTube" className="youtube-image-side" />

                                                                </Button>


                                                            </Link>



                                                        </div>

                                                        :
                                                        <></>

                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>



                            <Button
                                className='btn btn-danger border-0 text-white fw-bolder BottomButtons'
                                // onClick={() => window.open('tel:0722450690')}
                                onClick={handleAdd}
                            >
                                <i className='bi bi-telephone-fill me-2'></i>Call me
                            </Button>


                        </Tab>
                    </Tabs>
                )}

            </div>
            {selectedTicket && activeTab === "report" && <TicketView ticket={selectedTicket} onClose={() => setSelectedTicket(null)} />}
            <Modal show={showCallModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Enter phone number that you will be reached at</p>
                    <FormControl
                        type='number'
                        placeholder='Phone Number'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>Close</Button>
                    <Button variant='success' onClick={handleSavePhone}>Save</Button>
                </Modal.Footer>
            </Modal>



            {/* modal for sharing */}
            <Modal size="lg" show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-danger fw-bold m-auto'>SHARE THIS FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex">
                        <div
                            className="text-decoration-none text-dark w-50"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handlePlatform("whatsapp")}
                        >
                            <Col
                                md={6}
                                className="d-flex"
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "91%",
                                    marginInline: "0.5rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                            >
                                <Image
                                    src={Whatsapp}
                                    className="mb-2 me-2 mt-2 ms-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Share on WhatsApp</p>
                            </Col>
                        </div>

                        <div
                            className="text-decoration-none text-dark w-50"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handlePlatform("facebook")}
                        >
                            <Col
                                md={6}
                                className="d-flex"
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "91%",
                                    marginInline: "0.5rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                                onClick={() => shareFacebook()}
                            >
                                <Image
                                    src={Facebook}
                                    className="mb-2 me-2 mt-2 ms-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Share on Facebook</p>
                            </Col>
                        </div>
                    </Row>

                    <Row className="d-flex">
                        <div
                            className="text-decoration-none text-dark w-50"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handlePlatform("gmail")}
                        >
                            <Col
                                md={6}
                                className="d-flex"
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "91%",
                                    marginInline: "0.5rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                            >
                                <Image
                                    src={Mail}
                                    className="mb-2 me-2 mt-2 ms-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Share on Gmail</p>
                            </Col>
                        </div>

                        <div
                            className="text-decoration-none text-dark w-50"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handlePlatform("x")}
                        >
                            <Col
                                md={6}
                                className="d-flex"
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "91%",
                                    marginInline: "0.5rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                            >
                                <Image
                                    src={Twitter}
                                    className="mb-2 me-2 mt-2 ms-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Share on Twitter</p>
                            </Col>
                        </div>
                    </Row>

                    <Row className="d-flex">
                        <div
                            className="text-decoration-none text-dark w-50"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => handlePlatform("instagram")}
                        >
                            <Col
                                md={6}
                                className="d-flex"
                                style={{
                                    border: "1px solid #00abbdFF",
                                    width: "91%",
                                    marginInline: "0.5rem",
                                    marginTop: "1rem",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                }}
                                onMouseEnter={(e) =>
                                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                                }
                                onMouseLeave={(e) =>
                                    (e.currentTarget.style.backgroundColor = "inherit")
                                }
                            >
                                <Image
                                    src={Instagram}
                                    className="mb-2 me-2 mt-2 ms-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Share on Instagram</p>
                            </Col>
                        </div>

                        <Col
                            md={6}
                            style={{
                                border: "1px solid #00abbdFF",
                                width: "44%",
                                marginInline: "1.1rem",
                                marginTop: "1rem",
                                borderRadius: "10px",
                                cursor: "pointer",
                            }}
                            onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                            }
                            onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor = "inherit")
                            }
                            onClick={() => handlePlatform("copy_link")}
                        >
                            <div className="d-flex">
                                <Image
                                    src={Paperclip}
                                    className="mb-2 me-2 mt-2"
                                    style={{
                                        borderRadius: "10px",
                                        width: "auto",
                                        height: "5vh",
                                    }}
                                />
                                <p className="mt-3">Copy the link </p>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default HelpDesk;
