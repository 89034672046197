import React, { useState } from 'react';
import placeholderImage from '../../assets/img/campaign.png';
import '../../assets/scss/Campaign.scss'
import SideBar from '../components/Sidebar';
import Header from '../components/Header';
import SelectedCampaign from '../components/SelectedCampaign';
const generateRandomAmounts = () => {
  const raisedAmount = Math.floor(Math.random() * 200000) + 50000;
  const goalAmount = Math.floor(Math.random() * 500000) + 200000;
  const donationAmounts = [
    Math.floor(Math.random() * 500) + 100,
    Math.floor(Math.random() * 1000) + 500,
  ];

  return { raisedAmount, goalAmount, donationAmounts };
};

const Campaign = () => {
  const [amounts] = useState(generateRandomAmounts());


  return (
    <>
    <SideBar/>
    <Header/>
    <div className='homePage'>
      <SelectedCampaign
        category="CAMPAIGN NAME"
        title="Capstone Faith Ministry"
        raisedAmount={amounts.raisedAmount}
        goalAmount={amounts.goalAmount}
        donationAmounts={amounts.donationAmounts}
        image={placeholderImage}
      />
    </div>
    </>

  );
};

export default Campaign;