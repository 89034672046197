import React, { useEffect, useState } from 'react';
import { Card, Button, Image, Form } from 'react-bootstrap';
import '../../assets/scss/TicketView.scss';
import { serverUrl } from '../../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import Default from '../../assets/img/DefaultProfile.png'

const TicketView = ({ ticket, onClose }) => {
  const [ticketChats, setTicketChats] = useState([])
  const [userData, setUserData] = useState({
    full_name: 'You',
    profile_picture: Default
  });
  // get the access token
  const accessToken = localStorage.getItem('authTokens');
  if (accessToken) {

    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
  }
  const [isTyping, setIsTyping] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  // get the conversations
  const foundUser = localStorage.getItem('user')
  const foundHelpRequester = localStorage.getItem('help_requester')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await serverUrl.get(`/user/help-issue-conversation/?help_issue=${ticket.id}`);
        setTicketChats(response.data.results)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 3000);
    // get the user details
    if (accessToken) {
      // get the access token
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };
      // decoding the token to get the user id
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;
      serverUrl.get(`/user/user-details/?id=${userId}`, config)
        .then((res) => {
          setUserData(res.data.results[0])
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return () => clearInterval(intervalId);


  }, [])


  function handleAddResponse(e) {
    e.preventDefault();
    // update the response
    const formData = new FormData()
    let selectedImageType = null;

    if (selectedImage) {
      if (selectedImage.type.includes('video')) {
        selectedImageType = 'message_video';
      } else if (selectedImage.type.includes('image')) {
        selectedImageType = 'message_image';
      }
      formData.append(selectedImageType, selectedImage);
    }
    formData.append('message', responseText)
    formData.append('help_issue', ticket.id)
    formData.append('is_message_read', true)

    serverUrl.post(`/user/help-issue-conversation/`, formData)
      .then((res) => {
        toast.success('Thank you for your response we will get back to you shortly')
        serverUrl.get(`/user/help-issue-conversation/?help_issue=${ticket.id}`)
          .then((res) => {
            setTicketChats(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })

    // Reset states
    setSelectedImage(null);
    setResponseText('');
    setIsTyping(false);
  }

  const handleInputChange = (e) => {
    setResponseText(e.target.value);
    setIsTyping(e.target.value.trim().length > 0);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [month, day, year, time] = formattedDate.split(' ');
    const suffix = getDaySuffix(day);
    const meridiem = date.getHours() < 12 ? 'AM' : 'PM';
    return `${day}${suffix} ${month}, ${year} ${time} ${meridiem}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  return (
    <div className="ticket-view">
      <Card>
        <Card.Header>
          Ticket {ticket.ticket_number}
          {/* <span onClick={close}><b>X</b></span> */}
        </Card.Header>
        <Card.Body>
          {/* Display chat-like conversation here */}
          <div className="chat-conversation">
            {ticketChats.length === 0 ?
              <p className='text-danger text-center'>Sorry no conversations yet</p>
              :
              ticketChats.map((message, index) => (
                // ticketChats[ticket.ticket_number].map((message, index) => (
                <div className='d-flex justify-content-between'>
                  {message.is_help_desk_message ?
                    <div>

                      <div style={{ position: "relative" }}>
                        <Image style={{ borderRadius: "50%", background: "#00abbdFF", width: "11vw", height: "6vh" }} />
                        <span style={{ position: "absolute", fontWeight: "bolfer", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white" }}>HD</span>
                      </div>

                      <p style={{ fontSize: "12px", textAlign: "center" }}>H.D</p>
                    </div>
                    :
                    <div>

                      <Image src={userData.profile_picture} style={{ borderRadius: "50%", background: "#00abbdFF", width: "11vw", height: "6vh" }} />
                      <p style={{ fontSize: "12px", textAlign: "center" }}>You</p>
                    </div>
                  }


                  <div key={index} className={`chat-message`}>
                    {/* <div key={index} className={`chat-message ${message.sender.toLowerCase()}`}> */}
                    {index === 0 && (
                      <div className='d-flex justify-content-between'>
                        <p style={{ marginBottom: "5px" }} className="text-danger fw-bold">Ticket {message.help_issue.ticket_number}</p>
                        <p className='fw-bold' style={{ textTransform: "capitalize", marginBottom: "5px", color: message.help_issue.issue_status === 'pending' ? '#F39C12' : message.help_issue.issue_status === 'ongoing' ? '#E74C3C' : '#2ECC71' }}>{message.help_issue.issue_status}</p>
                      </div>
                    )}
                    {index === 0 && (
                      <p style={{ borderRadius: "10px", textTransform: "capitalize", marginBottom: "3px", width: "fit-content", padding: "2px", padding: "5px", backgroundColor: message.help_issue.issue_category === 'complaint' ? 'rgb(13, 157, 240)' : '#2ECC71', color: 'white', fontWeight: 'bold' }}>{message.help_issue.issue_category}</p>
                    )}
                    <div className='message-content'>
                      {message.message}
                    </div>
                    {message.message_image ?
                      <Image src={message.message_image} className='mt-2' style={{ width: "30%" }} />
                      : message.message_video ?
                        <video src={message.message_video} className='mt-2' style={{ width: "65%", display: "flex", height: "10vh", margin: "0 auto" }} controls autoPlay></video>
                        :
                        <></>
                    }
                    <span className="date">{formatDate(message.created_at)}</span>
                  </div>
                </div>
              ))
            }
            {selectedImage && (
              <div className="preview-image-container">
                <Image src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="preview-image"
                  style={{ width: "60%", height: "45vw" }} />
              </div>
            )}
          </div>

          <Button variant="secondary" className='d-none' onClick={onClose}>Close Ticket</Button>
        </Card.Body>



      </Card>
      {ticket.issues_status === 'seen and closed' ?
        <></>
        : ticket.issues_status === 'seen' ?
          <></>
          :
          <div className='d-flex justify-content-between BottomResponseTicket'>
            <input
              placeholder='Type your response here'
              className='inputSendMessage'
              value={responseText}
              onChange={handleInputChange}
            />

            <i
              className={`bi ${isTyping ? 'bi-send' : 'bi-camera'} response-icon imageDisplay`}
              onClick={isTyping ? handleAddResponse : () => document.getElementById('imageInput').click()}
              style={isTyping ? { transform: 'rotate(45deg)' } : null}
            ></i>

            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />

          </div>
      }


    </div>
  );
};

export default TicketView;



// import React, { useEffect, useState } from 'react';
// import { Card, Button, Image } from 'react-bootstrap';
// import '../../assets/scss/TicketView.scss';
// import { toast } from 'react-toastify';
// import Default from '../../assets/img/DefaultProfile.png';
// import { webSocketUrl, currentUrl } from '../../serverUrl';

// const TicketView = ({ ticket, onClose }) => {
//   const [ticketChats, setTicketChats] = useState([]);
//   const [userData, setUserData] = useState({
//     full_name: 'You',
//     profile_picture: Default
//   });
//   const [isTyping, setIsTyping] = useState(false);
//   const [responseText, setResponseText] = useState('');
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [webSocket, setWebSocket] = useState(null);

//   useEffect(() => {
//     // Open a WebSocket connection
//     const ws = new WebSocket(`${webSocketUrl}/issue-thread/${ticket.id}/`);

//     ws.onopen = () => {
//       console.log('WebSocket connected');
//     };

//     ws.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       setTicketChats(data.messages);
//     };

//     ws.onclose = () => {
//       console.log('WebSocket disconnected');
//     };

//     setWebSocket(ws);

//     // Cleanup function
//     return () => {
//       if (ws) {
//         ws.close();
//       }
//     };
//   }, [ticket.id]);

//   function handleAddResponse(e) {
//     e.preventDefault();

//     const data = {
//       'message': responseText,
//       'issue_id': ticket.id,
//     };

//     if (selectedImage) {
//       const reader = new FileReader();
//       reader.readAsDataURL(selectedImage);

//       reader.onloadend = () => {
//         const base64String = reader.result.split(',')[1];
//         data.media = base64String;

//         if (selectedImage.type.includes('video')) {
//           data.is_video = true;
//         } else if (selectedImage.type.includes('image')) {
//           data.is_image = true;
//         }

//         // Send message through WebSocket
//         webSocket.send(JSON.stringify(data));
//       };
//     } else {
//       // Send message through WebSocket without media data if no image selected
//       webSocket.send(JSON.stringify(data));
//     }

//     // Reset states
//     setSelectedImage(null);
//     setResponseText('');
//     setIsTyping(false);
//   }





//   const handleInputChange = (e) => {
//     setResponseText(e.target.value);
//     setIsTyping(e.target.value.trim().length > 0);
//   };

//   const handleImageUpload = (e) => {
//     const file = e.target.files[0];
//     setSelectedImage(file);
//   };

//   const formatDate = (dateString) => {
//     const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
//     const date = new Date(dateString);
//     const formattedDate = date.toLocaleDateString('en-US', options);
//     const [month, day, year, time] = formattedDate.split(' ');
//     const cleanedDay = day.replace(/,/g, ''); // Remove any commas in day
//     const suffix = getDaySuffix(parseInt(cleanedDay, 10)).replace(/,/g, ''); // Remove any commas in suffix
//     const meridiem = date.getHours() < 12 ? 'AM' : 'PM';
//     return `${cleanedDay}${suffix} ${month}, ${year} ${time} ${meridiem}`;
//   };


//   const getDaySuffix = (day) => {
//     if (day > 3 && day < 21) return 'th';
//     switch (day % 10) {
//       case 1: return 'st';
//       case 2: return 'nd';
//       case 3: return 'rd';
//       default: return 'th';
//     }
//   };
//   return (
//     <div className="ticket-view">
//       <Card>
//         <Card.Header>
//           {/* Ticket {ticket.ticket_number} */}
//           Ticket {ticket.ticket_number}
//         </Card.Header>
//         <Card.Body>
//           <div className="chat-conversation">
//             {ticketChats.length === 0 ?
//               <p className='text-danger text-center'>Sorry no conversations yet</p>
//               :
//               ticketChats.map((message, index) => (
//                 <div className='d-flex justify-content-between' key={index}>
//                   {message.technical_user_responded ?
//                     <div>
//                       <Image style={{ borderRadius: "50%", background: "#ff000073", width: "11vw", height: "6vh" }} />
//                       <p style={{ fontSize: "12px", textAlign: "center" }}>HD</p>
//                     </div>
//                     :
//                     <div>
//                       <Image src={`${currentUrl}${message.user_profile_pic}`} style={{ borderRadius: "50%", background: "#ff000073", width: "11vw", height: "6vh" }} />
//                       <p style={{ fontSize: "12px", textAlign: "center" }}>You</p>
//                     </div>
//                   }
//                   <div className={`chat-message`} key={index}>
//                     {index === 0 && (
//                       <div className='d-flex justify-content-between'>
//                         <p style={{ marginBottom: "5px" }} className="text-danger fw-bold">Ticket {message.issue_number}</p>
//                         <p className='fw-bold' style={{ textTransform: "capitalize", marginBottom: "5px", color: message.issue_status === 'pending' ? '#F39C12' : message.issue_status === 'ongoing' ? '#E74C3C' : '#2ECC71' }}>{message.issue_status}</p>
//                       </div>
//                     )}
//                     {index === 0 && (
//                       <p style={{ borderRadius: "10px", textTransform: "capitalize", marginBottom: "3px", width: "fit-content", padding: "2px", padding: "5px", backgroundColor: message.issue_type === 'complaint' ? 'rgb(13, 157, 240)' : '#2ECC71', color: 'white', fontWeight: 'bold' }}>{message.issue_type}</p>
//                     )}
//                     <div className='message-content'>
//                       {message.message}
//                     </div>
//                     {message.message_image ?
//                       <Image src={`${currentUrl}${message.message_image}`} className='mt-2' style={{ width: "30%" }} />
//                       : message.message_video ?
//                         <video src={`${currentUrl}${message.message_video}`} className='mt-2' style={{ width: "65%", display: "flex", height: "10vh", margin: "0 auto" }} controls autoPlay></video>
//                         :
//                         <></>
//                     }
//                     <span className="date">{formatDate(message.time)}</span>
//                   </div>
//                 </div>
//               ))
//             }
//             {selectedImage && (
//               selectedImage.type.includes('image') ? (
//                 <div className="preview-image-container">
//                   <Image src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="preview-image" style={{ width: "60%", height: "45vw" }} />
//                 </div>
//               )
//                 : (
//                   <div className="preview-image-container">
//                     <video src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="preview-image" style={{ width: "60%", height: "45vw" }} autoPlay controls />
//                   </div>
//                 )

//             )}
//           </div>
//           <Button variant="secondary" className='d-none' onClick={onClose}>Close Ticket</Button>
//         </Card.Body>
//       </Card>
//       {ticket.issues_status === 'pending' || ticket.issues_status === 'ongoing' ? (
//         <div className='d-flex justify-content-between BottomResponseTicket'>
//           <input
//             placeholder='Type your response here'
//             className='inputSendMessage'
//             value={responseText}
//             onChange={handleInputChange}
//           />

//           <i
//             className={`bi ${isTyping ? 'bi-send' : 'bi-camera'} response-icon imageDisplay`}
//             onClick={isTyping ? handleAddResponse : () => document.getElementById('imageInput').click()}
//             style={isTyping ? { transform: 'rotate(45deg)' } : null}
//           ></i>

//           <input
//             type="file"
//             id="imageInput"
//             accept="image/, video/**"
//             onChange={handleImageUpload}
//             style={{ display: 'none' }}
//           />
//         </div>
//       ) : null}



//     </div>
//   );
// };

// export default TicketView;


