import React from 'react';
import ReactMarkdown from 'react-markdown';

const YourComponent = () => {
  const markdownText = `
  
# PRIVACY NOTICE
#### Last updated May 30, 2022

This privacy notice for Jumuisha Limited (**"Company," "we," "us,"** or **"our"**),
describes how and why we might collect, store, use, and/or share (**"process"**) your
information when you use our services (**"Services"**), such as when you:

- Download and use our mobile application (Churches by Jumuisha), or any
  other application of ours that links to this privacy notice

- Engage with us in other related ways, including any sales, marketing, or
  events

**Questions or concerns?** Reading this privacy notice will help you understand your
privacy rights and choices. If you do not agree with our policies and practices, please
do not use our Services. If you still have any questions or concerns, please contact
us at info@jumuisha.com.


## SUMMARY OF KEY POINTS

**This summary provides key points from our privacy notice, but you can find
out more details about any of these topics by clicking the link following each
key point or by using our table of contents below to find the section you are
looking for.**

**What personal information do we process?** When you visit, use, or navigate our
Services, we may process personal information depending on how you interact with
Jumuisha Limited and the Services, the choices you make, and the products and
features you use.

**Do we process any sensitive personal information?** We do not process sensitive
personal information.

**Do you receive any information from third parties?** We may receive information
from public databases, marketing partners, social media platforms, and other outside
sources.

**How do you process my information?** We process your information to provide,
improve, and administer our Services, communicate with you, for security and fraud
prevention, and to comply with law. We may also process your information for other
purposes with your consent. We process your information only when we have a valid
legal reason to do so.

**In what situations and with which parties do we share personal information?**
We may share information in specific situations and with specific third parties.


**How do we keep your information safe?** We have organizational and technical
processes and procedures in place to protect your personal information. However, no
electronic transmission over the internet or information storage technology can be
guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
cybercriminals, or other unauthorized third parties will not be able to defeat our
security and improperly collect, access, steal, or modify your information.
Click here to learn more.

**What are your rights?** Depending on where you are located geographically, the
applicable privacy law may mean you have certain rights regarding your personal
information.

**How do I exercise my rights?** The easiest way to exercise your rights is by filling
out our data subject request form available here: http://www.jumuisha.com/web/myprofile, or by contacting us. We will consider and act upon any request in accordance
with applicable data protection laws.

Want to learn more about what Jumuisha Limited does with any information we
collect?


## TABLE OF CONTENTS

1. WHAT INFORMATION DO WE COLLECT?
2. HOW DO WE PROCESS YOUR INFORMATION?
3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
5. HOW LONG DO WE KEEP YOUR INFORMATION?
6. HOW DO WE KEEP YOUR INFORMATION SAFE?
7. DO WE COLLECT INFORMATION FROM MINORS?
8. WHAT ARE YOUR PRIVACY RIGHTS?
9. CONTROLS FOR DO-NOT-TRACK FEATURES
10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
11. DO WE MAKE UPDATES TO THIS NOTICE?
12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
    FROM YOU?

### 1. WHAT INFORMATION DO WE COLLECT?

**Personal information you disclose to us**

**In Short:** *We collect personal information that you provide to us*.

We collect personal information that you voluntarily provide to us when you register
on the Services, express an interest in obtaining information about us or our products
and Services, when you participate in activities on the Services, or otherwise when
you contact us.

**Personal Information Provided by You.** The personal information that we collect
depends on the context of your interactions with us and the Services, the choices you
make, and the products and features you use. The personal information we collect
may include the following:

- names
- phone numbers
- email addresses
- passwords

**Sensitive Information.** We do not process sensitive information.

**Payment Data.** We may collect data necessary to process your payment if you make
purchases, such as your payment instrument number (such as a credit card number),
and the security code associated with your payment instrument. All payment data is stored by Safaricom. You may find their privacy notice link(s) here:
https://www.safaricom.co.ke/dataprivacystatement/.

**Application Data.** If you use our application(s), we also may collect the following
information if you choose to provide us with access or permission:

- Mobile Device Data. We automatically collect device information (such as your
  mobile device ID, model, and manufacturer), operating system, version
  information and system configuration information, device and application
  identification numbers, browser type and version, hardware model Internet
  service provider and/or mobile carrier, and Internet Protocol (IP) address (or
  proxy server). If you are using our application(s), we may also collect
  information about the phone network associated with your mobile device, your
  mobile device’s operating system or platform, the type of mobile device you
  use, your mobile device’s unique device ID, and information about the features
  of our application(s) you accessed.

- Push Notifications. We may request to send you push notifications regarding
  your account or certain features of the application(s). If you wish to opt out
  from receiving these types of communications, you may turn them off in your
  device's settings.

This information is primarily needed to maintain the security and operation of our
application(s), for troubleshooting, and for our internal analytics and reporting
purposes.

All personal information that you provide to us must be true, complete, and accurate,
and you must notify us of any changes to such personal information.

### Information automatically collected

**In Short:** *Some information — such as your Internet Protocol (IP) address and/or
browser and device characteristics — is collected automatically when you visit our
Services*.

We automatically collect certain information when you visit, use, or navigate the
Services. This information does not reveal your specific identity (like your name or
contact information) but may include device and usage information, such as your IP
address, browser and device characteristics, operating system, language
preferences, referring URLs, device name, country, location, information about how
and when you use our Services, and other technical information. This information is
primarily needed to maintain the security and operation of our Services, and for our
internal analytics and reporting purposes.

Like many businesses, we also collect information through cookies and similar
technologies.

The information we collect includes:

- Log and Usage Data. Log and usage data is service-related, diagnostic,
  usage, and performance information our servers automatically collect when
  you access or use our Services and which we record in log files. Depending on
  how you interact with us, this log data may include your IP address, device
  information, browser type, and settings and information about your activity in
  the Services (such as the date/time stamps associated with your usage, pages
  and files viewed, searches, and other actions you take such as which features
  you use), device event information (such as system activity, error reports
  (sometimes called "crash dumps"), and hardware settings).

- Device Data. We collect device data such as information about your computer,
  phone, tablet, or other device you use to access the Services. Depending on
  the device used, this device data may include information such as your IP
  address (or proxy server), device and application identification numbers,
  location, browser type, hardware model, Internet service provider and/or
  mobile carrier, operating system, and system configuration information.

### Information collected from other sources

**In Short:** *We may collect limited data from public databases, marketing partners, and
other outside sources*.

In order to enhance our ability to provide relevant marketing, offers, and services to
you and update our records, we may obtain information about you from other
sources, such as public databases, joint marketing partners, affiliate programs, data
providers, and from other third parties. This information includes mailing addresses,
job titles, email addresses, phone numbers, intent data (or user behavior data),
Internet Protocol (IP) addresses, social media profiles, social media URLs, and
custom profiles, for purposes of targeted advertising and event promotion.

### 2. HOW DO WE PROCESS YOUR INFORMATION?

**In Short:** *We process your information to provide, improve, and administer our
Services, communicate with you, for security and fraud prevention, and to comply
with law. We may also process your information for other purposes with your consent*.

**We process your personal information for a variety of reasons, depending on
how you interact with our Services, including:**

- **To facilitate account creation and authentication and otherwise manage
  user accounts.** We may process your information so you can create and log
  in to your account, as well as keep your account in working order.

- **To deliver and facilitate delivery of services to the user.** We may process
  your information to provide you with the requested service.

### 3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?

**In Short:** *We may share information in specific situations described in this section
and/or with the following third parties*.

**Vendors, Consultants, and Other Third-Party Service Providers.** We may share
your data with third-party vendors, service providers, contractors, or agents (“**third
parties**”) who perform services for us or on our behalf and require access to such
information to do that work. The third parties we may share personal information with
are as follows:
- **Cloud Computing Services**

Microsoft Azure and Google Cloud Platform

- **Data Backup and Security**

Google Drive Backup

- **Web and Mobile Analytics**

Google Analytics

We also may need to share your personal information in the following situations:

- **Business Transfers.** We may share or transfer your information in connection
  with, or during negotiations of, any merger, sale of company assets, financing,
  or acquisition of all or a portion of our business to another company.

### 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?

**In Short:** *We may use cookies and other tracking technologies to collect and store
your information*.

We may use cookies and similar tracking technologies (like web beacons and pixels)
to access or store information. Specific information about how we use such
technologies and how you can refuse certain cookies is set out in our Cookie Notice.

### 5. HOW LONG DO WE KEEP YOUR INFORMATION?

**In Short:** *We keep your information for as long as necessary to fulfill the purposes
outlined in this privacy notice unless otherwise required by law*.

We will only keep your personal information for as long as it is necessary for the
purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose
in this notice will require us keeping your personal information for longer than the
period of time in which users have an account with us.

When we have no ongoing legitimate business need to process your personal
information, we will either delete or anonymize such information, or, if this is not
possible (for example, because your personal information has been stored in backup
archives), then we will securely store your personal information and isolate it from
any further processing until deletion is possible.

### 6. HOW DO WE KEEP YOUR INFORMATION SAFE?

**In Short:** *We aim to protect your personal information through a system of
organizational and technical security measures*.

We have implemented appropriate and reasonable technical and organizational
security measures designed to protect the security of any personal information we
process. However, despite our safeguards and efforts to secure your information, no
electronic transmission over the Internet or information storage technology can be
guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
cybercriminals, or other unauthorized third parties will not be able to defeat our
security and improperly collect, access, steal, or modify your information. Although
we will do our best to protect your personal information, transmission of personal
information to and from our Services is at your own risk. You should only access the
Services within a secure environment.

### 7. DO WE COLLECT INFORMATION FROM MINORS?

**In Short:** *We do not knowingly collect data from or market to children under 18 years
of age*.

We do not knowingly solicit data from or market to children under 18 years of age. By
using the Services, you represent that you are at least 18 or that you are the parent
or guardian of such a minor and consent to such minor dependent’s use of the
Services. If we learn that personal information from users less than 18 years of age
has been collected, we will deactivate the account and take reasonable measures to
promptly delete such data from our records. If you become aware of any data we
may have collected from children under age 18, please contact us at
garang@jumuisha.com.

### 8. WHAT ARE YOUR PRIVACY RIGHTS?

**In Short:** *You may review, change, or terminate your account at any time*.

If you are located in the EEA or UK and you believe we are unlawfully processing
your personal information, you also have the right to complain to your local data
protection supervisory authority. You can find their contact details here:
https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

If you are located in Switzerland, the contact details for the data protection authorities
are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.

**Withdrawing your consent:** If we are relying on your consent to process your
personal information, which may be express and/or implied consent depending on
the applicable law, you have the right to withdraw your consent at any time. You can
withdraw your consent at any time by contacting us by using the contact details
provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
below.


However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

**Opting out of marketing and promotional communications:** You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.

**Account Information**

If you would at any time like to review or change the information in your account or terminate your account, you can:

- Contact us using the contact information provided.

Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.

**Cookies and similar technologies:** Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.

If you have questions or comments about your privacy rights, you may email us at info@jumuisha.com.

###  9.	CONTROLS FOR DO-NOT-TRACK FEATURES

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

### 10.	DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?

**In Short:** *Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information*.

California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account
with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us
using the contact information provided below and include the email address
associated with your account and a statement that you reside in California. We will
make sure the data is not publicly displayed on the Services, but please be aware
that the data may not be completely or comprehensively removed from all our
systems (e.g., backups, etc.).

### 11. DO WE MAKE UPDATES TO THIS NOTICE?

**In Short:** *Yes, we will update this notice as necessary to stay compliant with relevant
laws*.

We may update this privacy notice from time to time. The updated version will be
indicated by an updated "Revised" date and the updated version will be effective as
soon as it is accessible. If we make material changes to this privacy notice, we may
notify you either by prominently posting a notice of such changes or by directly
sending you a notification. We encourage you to review this privacy notice frequently
to be informed of how we are protecting your information.

### 12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?

If you have questions or comments about this notice, you may contact our Data
Protection Officer (DPO), Paul Garang, by email at garang@jumuisha.com, by phone
at 254700402788, or by post to:

Jumuisha Limited

Paul Garang

Moi Avenue

Nairobi, Nairobi 30513

Kenya

### 13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
Based on the applicable laws of your country, you may have the right to request
access to the personal information we collect from you, change that information, or
delete it in some circumstances. To request to review, update, or delete your
personal information, please visit: http://www.jumuisha.com/web/my-profile.

`;
  return (
    <div>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </div>
  );
}

export default YourComponent;
