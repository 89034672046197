import React, { useState } from 'react';
import { Card, Container, Button, Modal, FormControl } from 'react-bootstrap';
import '../../assets/scss/ReportIssue.scss';
import PlaceholderImage from '../../assets/img/placeholder-image.png';
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap/esm';

function ReportIssue() {
    const [selectedCategory, setSelectedCategory] = useState('complaint');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const [isLoading, setLoading] = useState(false)
    const [media, setMedia] = useState(null);
    const navigate = useNavigate()

    const topics = [
        'my account',
        'making a pledge',
        'redeeming a pledge',
        'signing up',
        'resetting password',
        'others'
    ];

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedTopic('');
    };

    const handleTopicSelect = (topic) => {
        setSelectedTopic(topic);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setMedia(file);
    };

    const handlePlaceholderClick = () => {
        document.getElementById('fileInput').click();
    };

    const goBack = () => {
        window.history.back();
    };

    const accessToken = localStorage.getItem('authTokens');
    if (accessToken) {
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` },
        };
        // decoding the token to get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    }
    const foundUser = localStorage.getItem('user')
    const foundHelpRequester = localStorage.getItem('help_requester')
    const [showCallModal, setShowCallModal] = useState(false)
    const handleAdd = () => {
        setShowCallModal(true)
    }
    const handleClose = () => {
        setShowCallModal(false)
    }
    function handleAddReport(e) {
        e.preventDefault();
        let mediaType = null;
        let formData = new FormData();
        let formDataConversation = new FormData();

        if (media) {
            if (media.type.includes('video')) {
                mediaType = 'complain_video';
            } else if (media.type.includes('image')) {
                mediaType = 'complain_image';
            }
            formData.append(mediaType, media);
        }
        // Check if foundUser is available
        if (foundUser) {
            formData.append('user', foundUser);
        }
        // Check if foundHelpRequester is available
        else if (foundHelpRequester) {
            formData.append('support_requester', foundHelpRequester);
        }
        // If neither foundUser nor foundHelpRequester is available, attempt to fetch user ID from access token
        else {
            const accessToken = localStorage.getItem('authTokens');
            if (accessToken) {
                try {
                    const parsedTokens = JSON.parse(accessToken);
                    const access = parsedTokens.access;
                    const decodedToken = jwtDecode(accessToken);
                    const userId = decodedToken.user_id;
                    formData.append('user', userId);
                } catch (error) {
                    console.error('Error parsing access token:', error);
                }
            } else {
                console.error('Access token not found');
            }
        }
        if (!selectedCategory) {
            return toast.error('Ensure you have selected a category')
        }
        if (!selectedTopic) {
            return toast.error('Ensure you have selected a topic')
        }
        if (!additionalComments) {
            return toast.error('Ensure you have added the comments')
        }
        formData.append('issue_category', selectedCategory);
        formData.append('issues_status', 'pending');
        formData.append('topic', selectedTopic);
        formData.append('additional_comments', additionalComments);

        // Post the data 
        setLoading(true)

        serverUrl.post(`/user/help-issue/`, formData)
            .then((res) => {
                setLoading(false)
                navigate('/help-desk')
                toast.success('Succesfully reported the issue kindly wait for a response')
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const [phoneNumber, setPhoneNumber] = useState('')
    function handleSavePhone(e) {
        e.preventDefault()
        const data = {
            phone_number: phoneNumber,
            call_status: 'pending'
        }
        serverUrl.post(`/user/helpdesk-call-phone-number/`, data)
            .then((res) => {
                setShowCallModal(false)
                setPhoneNumber(null)
                toast.success('Thank you for your response. Kindly wait as our team gets back to you')
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className='reportIssue'>
            <div className='d-flex'>
                <p onClick={goBack}>
                    <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem", marginTop: "0.5rem" }}></i>
                </p>
                <h2 className='text-dark text-center' style={{ alignContent: "center", marginLeft: "6rem", fontSize: "30px", fontWeight: "500" }}>Help Desk</h2>
            </div>

            <Container>
                <Card>
                    <div className="mt-4">
                        <p className="text-dark">Categorise your issue:</p>

                        <div className="TopDisplayGroups" role="group">
                            <button type="button" className={`btn me-2 ${selectedCategory === 'complaint' ? 'btn-danger border-0 text-white fw-bold' : 'btn-outline-dark'}`} onClick={() => handleCategorySelect('complaint')}>Complaint</button>
                            <button type="button" className={`btn ${selectedCategory === 'suggestion' ? 'btn-danger border-0 text-white fw-bold' : 'btn-outline-dark'}`} onClick={() => handleCategorySelect('suggestion')}>Suggestion</button>
                        </div>
                    </div>

                    {selectedCategory && (
                        <div className="mt-4">
                            <p className="text-dark">Select Topic:</p>
                            <div className="CategoryButtons" role="group" aria-label="Issue Topic">
                                {topics.map((topic, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{ textTransform: "capitalize" }}
                                        className={`btn ${selectedTopic === topic.toLowerCase() ? 'btn-danger border-0 btn-outline-secondary text-white' : 'btn-outline-dark'}`}
                                        onClick={() => handleTopicSelect(topic.toLowerCase())}
                                    >
                                        {topic}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="mt-4">
                        <p className="text-dark">Additional Comments:</p>
                        <textarea className="form-control" rows="6" placeholder="Provide as much information as possible to help us quickly resolve your issue.
    DO NOT provide sensitive information like passwords, PINs etc." value={additionalComments} onChange={(e) => setAdditionalComments(e.target.value)}></textarea>
                    </div>

                    <div className="mt-4">
                        <p className="text-dark">Upload Screenshot/Video Recording of Issue:</p>
                        <div className="custom-file" onClick={handlePlaceholderClick}>
                            <input type="file" className="custom-file-input" id="fileInput" onChange={handleFileUpload} accept="image/*, video/*" style={{ display: 'none' }} />
                            <label className="custom-file-label" htmlFor="fileInput">
                                {media ? (
                                    media.type.includes('image') ? (
                                        <img src={URL.createObjectURL(media)} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                                    ) : (
                                        <video controls autoPlay muted style={{ maxWidth: '100%', maxHeight: '150px' }}>
                                            <source src={URL.createObjectURL(media)} type={media.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    )
                                ) : (
                                    <img src={PlaceholderImage} alt="Placeholder" style={{ width: '150px', height: '150px', cursor: 'pointer', padding: "5px" }} />
                                )}
                            </label>
                        </div>
                    </div>
                    {isLoading ?
                        <Button style={{ width: "40vw", height: "5vh", borderRadius: "20px" }} className='btn btn-dark text-white btn-sm mt-2'><Spinner /></Button>
                        :
                        <Button style={{ width: "40vw", height: "5vh", borderRadius: "20px" }} className='btn btn-dark text-white btn-sm mt-2' onClick={handleAddReport}>Submit Response</Button>

                    }

                </Card>
                <Modal show={showCallModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Enter phone number that you will be reached at</p>
                        <FormControl
                            type='number'
                            placeholder='Phone Number'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>Close</Button>
                        <Button variant='success' onClick={handleSavePhone}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <Button onClick={handleAdd}
                    className=' btn btn-danger border-0 text-white fw-bolder BottomButtons'><i className='bi bi-telephone-fill me-2'></i>Call me</Button>

            </Container>
        </div>
    );
}

export default ReportIssue;
